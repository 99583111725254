<template>

  <div class="post-status float-right">
    <span :class="cls" v-if="post.status==='D'">{{updatedAt}}</span>
    <span :class="cls" v-else>{{post.status|status(partner)|titlecase}}</span>
    <span :class="cls" v-if="published||scheduled">{{publishAt}}</span>
  </div>

</template>

<script>

import { getters } from '@/services/store'

const moment = require('moment')

export default {
  name: 'Status',
  data() {
    return {
      cls:'',
      partner:'',
      published:false,
      scheduled:false
    }
  },
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  computed: {
    updatedAt() {
      return moment(this.post.updatedAt).format('MM/DD/YYYY')
    },
    publishAt() {
      return moment(this.post.publishAt).format('MM/DD/YYYY h:mm a')
    }
  },
  created() {

    this.partner = getters.partner()
    this.published = ['P','T'].includes(this.post.status) && this.post.publishAt
    this.scheduled = ['S'].includes(this.post.status) && this.post.publishAt

    this.cls =  this.post.status === 'P' ? 'text-success' :
                this.post.status === 'F' ? 'text-danger' :
                this.post.status === 'D' ? 'text-info' :
                this.post.status === 'S' ? 'text-info' :
                this.post.status === 'T' ? 'text-success' : ''
  }
}
</script>

<style lang="scss" >
.post-status {

  span {
    padding:5px;
  }
}

</style>
