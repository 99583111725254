<template>
  <div class="container channels-list">
    <b-overlay :show="busy">
      <div class="row">
        <div class="col-md-12">
          <h3 class="msg">Your Connected {{partner|partnerName}} Channels
            <small class="helplink"><a v-if="partner==='lately'" target="_blank" href="https://www.lately.ai/resources/connect-instagram-to-lately">Having trouble connecting Instagram?</a></small>
            <span class="float-right channel-actions">
              <b-button @click="connect()" size="sm" variant="primary">CONNECT</b-button>
              <b-button v-if="includes('connect-third-party') && canChangePublisher" variant="secondary" @click="initChangePublisher()" size="sm">CHANGE PUBLISHER</b-button>
            </span>
          </h3>
        </div>
        <div class="col-md-12">

          <ConfirmDialog id="remove-channel-dialog" title="Remove channel?" :click="completeRemoveChannel" :open="removeChannelDialog" />
          <ConfirmDialog id="change-publisher-dialog" title="Change Publisher" description="To connect a different publisher we'll need to
          remove all of your connected channels, and all content associated with each channel. Would you like to proceed?" :click="completeInitChangePublisher" :open="changePublisherDialog" />

          <b-table-simple hover striped bordered class="channels-table">
            <b-tbody>
            <b-tr v-for="channel in connected" :key="channel._id">
              <b-td>
                <h4>
                  <small>
                    <b-avatar variant="info" :src="channel.content.logo" class="mr-3"></b-avatar>
                    <ChannelIcon size="lg" :type="channel.type" />
                    {{channel.content.name||channel.content.displayName}}
                    <small class="channel-status float-right">

                      <span v-if="channel.alert" class="text-danger">Authentication expired! Please reconnect</span>
                      <span v-if="channel.auth.needsReauth" class="text-danger">Authentication expired! Please reconnect</span>
                      <span v-if="channel.authed && !channel.auth.needsReauth" class="text-success">Connected <i class="fa fa-check-circle" /></span>

                      <b-dropdown size="md" variant="link" toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                          <span class="fa-stack">
                            <i class="fa fa-circle fa-stack-2x icon-background" style="color:white"></i>
                            <i class="fa fa-ellipsis-v fa-stack-1x"></i>
                          </span>
                        </template>
                        <b-dropdown-item @click="remove(channel)">Disconnect</b-dropdown-item>
                      </b-dropdown>

                    </small>
                  </small>
                </h4>
                <p class="channel-link"><a target="_" :href="channel.url">{{channel.url}}</a></p>
              </b-td>
            </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>

      <!-- channels remaining based on product limits -->
      <ChannelsRemaining :remaining="remaining" :selections="selections" />
    </b-overlay>
  </div>
</template>

<script>

import { actions, getters } from '@/services/store'
import { includes } from '@/services/product'

import ConfirmDialog from '@/components/ConfirmDialog'
import ChannelsRemaining from './ChannelsRemaining'
import ChannelIcon from '@/components/ChannelIcon'

export default {

  name: 'ChannelsList',

  data() {
    return {
      busy:false,
      channel: false,
      limitMessage:'',
      dashboard: false,
      includes: includes,
      partner: 'Connected',
      canChangePublisher: false,
      removeChannelDialog: false,
      changePublisherDialog: false,
    }
  },

  props: {
    remaining: Object,
    selections: Object,
  },

  computed: {

   canConnect() {
      return Object.keys(this.remaining).filter((key)=>{
        return this.remaining[key] > 0
      }).length !== 0
    },

    canComplete() {
      return Object.keys(this.remaining).filter((key)=>{
        return this.remaining[key] < 0
      }).length === 0
    },

    connected() {
      return this.dashboard.channels.filter((ch)=>{return ch.content && !ch.needsSelection})
    }
  },

  created() {

    this.fetchPartner()
    this.dashboard = getters.dashboard()
    this.canChangePublisher = !getters.isIframe('hootsuite')

    if ( typeof this.remaining.all === 'number' ) {
      this.limitMessage = this.remaining.all? `You may connect ${this.remaining.all} more channels` : `You have used your channel allocation`
    } else {
      this.limitMessage = Object.keys(this.remaining).reduce((acc,key)=>{
        acc.push(`${this.remaining[key]} more ${key} channels`)
        return acc
      },[]).join(', ')
    }

  },

  watch: {
    'dashboard.channels': {
      handler () {
        this.fetchPartner()
      },
      deep: true
    }
  },

  methods: {

    fetchPartner() {
      this.partner = getters.partner()
    },

    remove(channel,complete) {
      this.channel = channel;
      this.removeChannelDialog = true;
    },

    initChangePublisher() {
      this.changePublisherDialog = true
    },

    async completeInitChangePublisher(confirm) {
      this.changePublisherDialog = false
      if ( confirm ) {
        try {
          this.busy = true
          const channels = this.dashboard.channels.reduce((acc,ch)=>{
            acc[ch._id] = 1
            return acc
          },{})
          await actions.updateChannels( this.dashboard, channels, 'remove' )
          this.$router.go()
        } catch ( err ) {
          console.error(err)
          this.$toasted.error(`Unable to remove channel, please try again in a few minutes`)
        } finally {
          this.busy = false
        }
      }
    },

    async completeRemoveChannel(confirm) {
      if ( confirm ) {
        try {
          const channels = [this.channel].reduce((acc,ch)=>{
            acc[ch._id] = 1
            return acc
          },{})
          await actions.updateChannels( this.dashboard, channels, 'remove' )
          const idx = this.dashboard.channels.indexOf(this.channel)
          this.dashboard.channels.splice(idx,1)
          this.$emit('on-channel')
          this.$toasted.success(`Channel removed`)
        } catch ( err ) {
          console.error(err)
          this.$toasted.error(`Unable to remove channel, please try again in a few minutes`)
        }
      }
      this.removeChannelDialog = false;
      this.channel = false;
    },

    connect() {
      this.$emit('on-connect-publisher')
    }

  },

  components: {
    ChannelsRemaining,
    ConfirmDialog,
    ChannelIcon
  }

}
</script>

<style lang="scss" >

.channels-list {

  font-family: Noto Sans;

  .channels-table {
    width: 100%;
    margin-top: 10px;    
  }

  .channel-status {
    text-align: right;
    margin-top: 5px;
  }

  .channel-status span {
    font-weight: 1.1em;
  }

  p.channel-link {
    margin:0px;
  }

  .channel-actions button {
    margin-left:10px;
    margin-top:0px;
  }

  p {
    font-size: 13px;
    font-weight: bold;
  }

  .helplink {
    font-size: 14px;
  }

  .fa-stack.small { font-size: 0.6em; }

  .dropdown-toggle {
    font-size:12px;
    margin-top: 0px;
    background-color: transparent;
  }

  tr {
    p {
      font-size: 12px;
    }
  }

  .channels-remaining {
    p {
      margin-top: 0px;
      font-size: 12px;
    }
  }

}

</style>
