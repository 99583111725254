<template>

  <div class="container signup-page publisher-info">

    <div class="row" v-if="ready">

      <div class="col-md-12">
        <h3 class="title">Once Lately's AI generates your social posts, how would you like to publish them? </h3>
        <p class="info">
          Choose Lately as your social media publisher or choose one of our integrated publishing platforms:
        </p>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-12" v-for="publisher in publishers" :key="publisher.id">

        <div class="publisher text-center nopad">

          <div class="d-flex flex-column">

              <div class="p2">
                <b-img fluid :src="logoFor(publisher)" />
              </div>

              <div class="p2 flex-grow-* description" style="height:100%">
                <p v-html="publisher.description"></p>
              </div>

              <div class="p2 action" @click="select(publisher)">
                <span>PUBLISH WITH {{publisher.id|upperCase}}</span>
              </div>

          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

import { Publishers } from '@/services/constants'
import { getters } from '@/services/store'

export default {

  name: 'PublisherInfo',

  data() {
    return {
      ready: false,
      publishers:Publishers.filter((p)=>p.enabled)
    }
  },

  created() {

    // when hosted in Hootsuite select hootsuite publisher and move along
    if ( getters.isIframe('hootsuite') ) {
      const hs = this.publishers.find((p)=>{return p.id === 'hootsuite'})
      this.select(hs)
    } else this.ready = true

  },

  methods: {

    logoFor(publisher) {
      return require(`@/assets/integrations/${publisher.img}`)
    },

    select(publisher) {
      this.$emit('publisher-info-selected',{
        id: publisher.id,
        img: publisher.img,
        summary: publisher.summary
      })
    },

  }

}
</script>

<style lang="scss" >

.publisher-info {

  margin-top: 50px;

  .title {
    margin-bottom: 24px;
  }

  .info {
    font-size: 24px;
    font-weight: normal;
  }

  .publisher {

    font-size: 15px;
    text-align: center;
    border: 1px solid #D6D6D6;
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 30px;
    box-shadow: 2px 2px 2px lightgrey;

    img {
      padding: 12px;
      height: 85px;
      margin: 36px 0px 36px 0px;
    }

    .description {
      p {
        padding: 0px 30px 0px 30px;
        font-size: 15px;
      }
    }

    .action {
      font-size: 18px;
      font-weight: bold;
      color: white;
      padding: 20px;
      cursor: pointer;
      min-height: 100px;
      background-color: #20C763;
      border-radius: 0px 0px 10px 10px;
    }

  }

}

</style>
