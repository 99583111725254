<template>

  <div class="container-fluid drafts-view nomargin nopad">
    <ListView title="Outbox" :config="config" />
  </div>

</template>

<script>

import ListView from '@/components/ContentView/ListView'
import { getters } from '@/services/store'

export default {

  name: 'Outbox',

  data() {
    return {
      config: {
        actions: [{
          id:'remove',
          partner: '*',
          float:'right',
          title: {
            prefix: 'CLEAR',
            suffix: 'POSTS',
          },
          variant:'outline-secondary'
        }],
        filter: {
          channels: {
            value:'*',
            editable:true
          },
          status:{
            value: (getters.partner() === 'lately'? 'P,T,S,F' : 'P,T,F'),
            editable:true
          }
        }
      }
    }
  },

  components: {
    ListView
  }

}
</script>

