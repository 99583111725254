<template>

    <!-- page header -->
    <div class="container fluid" v-if="session" :key="session._id" >

      <!-- for confirmation of session removal -->
      <ConfirmDialog title="Are you sure? Work in progress will be lost." :click="abortConfirm" :open="dialogOpen"/>

      <GeneratorWizard :session="session" :key="session._id" :policy="policy" />

    </div>

</template>

<script>

'use strict'

import GeneratorWizard from '@/components/GeneratorWizard';
import ConfirmDialog from '@/components/ConfirmDialog';
import { actions }  from '@/services/store.js';

export default {

  name: 'Generator',

  data() {
    return {
      dialogOpen:false,
      session:false,
      policy:false,
      next:false
    }
  },

  async created() {

    this.policy = await actions.fetchPolicy()

    const session = await actions.findSession(this.$route.params.sessionId);

    // reset step on regenerate
    if ( session && session.status.bucket === 'complete' ) {
      session.config.step = 'channels'
    }

    // add newly introduced params
    session.config.steps.source.editTranscript = (session.config.steps.source.editTranscript || false)

    this.session = session;

  },

  methods: {

    async abortConfirm(confirm) {
      this.dialogOpen = false;
      if ( confirm ) {
        await actions.removeSession(this.session)
        this.session = await actions.findSession(this.$route.params.sessionId);
        this.next()
      } else this.next(false)
    }
  },

  async beforeRouteUpdate(to, from, next) {
    // console.log('beforeRouteUpdate',to,from)
    if ( false && this.session && this.session.status.bucket === 'open' && this.session.config.step != 'source' ) {
      this.next = next;
      this.dialogOpen = true;
    } else {
      this.session = await actions.findSession(this.$route.params.sessionId);
      next()
    }
  },

  async beforeRouteLeave(to, from, next) {
    if ( this.session ) {
      const status = this.session.status
      const source = this.session.config.steps.source
      if ( status.bucket === 'open' && source.valid ) {
        this.next = next;
        this.dialogOpen=true;
      } else if ( status.bucket === 'open' ) {
        await actions.removeSession(this.session)
        next()
      } else next()
    } else next()
  },

  components: {
    GeneratorWizard,
    ConfirmDialog
  },

}
</script>

