<template>

  <div class="session-results text-right">

      <p class="legend">
        <small v-if="session.status.bucket==='complete'"> {{session.postsRemaining.all? '':'No'}} Results left to review</small>
      </p>

      <p>

        <span class="channel-busy-indicator" v-if="!['complete','failed','transcribed'].includes(session.status.bucket)">
          <i class="fa fa-fw fa-circle-notch fa-spin fa-2x session-busy-indicator" />
        </span>
        <span class="channel-count" v-if="includes('send-third-party,send-lately,generate-export') && session.postsCreated.twitter">
          <ChannelIcon size="2x" type="twitter" :numericBadge="session.postsRemaining.twitter"  :checkBadge="(session.postsRemaining.twitter==0)"/>
        </span>
        <span class="channel-count" v-if="includes('send-third-party,send-lately,generate-export') && session.postsCreated.linkedin">
          <ChannelIcon size="2x" type="linkedin" :numericBadge="session.postsRemaining.linkedin" :checkBadge="(session.postsRemaining.linkedin==0)"/>
        </span>
        <span class="channel-count" v-if="includes('send-third-party,send-lately,generate-export') && session.postsCreated.facebook" >
          <ChannelIcon size="2x" type="facebook" :numericBadge="session.postsRemaining.facebook" :checkBadge="(session.postsRemaining.facebook==0)"/>
        </span>
        <span class="channel-count" v-if="includes('send-third-party,send-lately,generate-export') && session.postsCreated.instagram">
          <ChannelIcon size="2x" type="instagram" :numericBadge="session.postsRemaining.instagram" :checkBadge="(session.postsRemaining.instagram==0)"/>
        </span>
        <span class="channel-count" v-if="!includes('send-third-party,send-lately,generate-export') && session.postsRemaining.all">
          <i class="fa fa-lg fa-share-alt-square" :numericBadge="session.postsRemaining.all"/>
        </span>

      </p>

  </div>

</template>

<script>

import ChannelIcon from '@/components/ChannelIcon'
import { includes } from '@/services/product'
import { media } from '@/services/constants'

export default {

  name: 'SessionResults',

  props: {
    session: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      includes
    }
  },

  methods: {

   colorFor(ch) {
      return media[ch].color
    },

    borderFor(ch) {
      return media[ch].color
    },

    iconFor(ch) {
      switch(ch) {
        case 'facebook': return 'facebook-f';
        case 'linkedin': return 'linkedin-in'
        default: return ch
      }
    }
  },

  components: {
    ChannelIcon
  }

}

</script>

<style lang="scss" >

.session-results {

  p.legend {
    margin-bottom: 10px;
    color: grey;
  }

  margin-right: 24px;
  .channel-count {
    padding: 0px;
    max-width: 20px!important;
  }

  .channel-busy-indicator {
    color:#20C763;
    position: relative;
    top: 6px;
  }

}

</style>
