<template>

  <div class="media-player-wrapper">
    <video ref="media-player" controls="" class="video" v-if="source.mimetype.startsWith('video')" >
      <source :src="source|preview(policy)" :type="source.mimetype"/>
      Your browser does not support HTML5 video.
    </video>
    <video ref="media-player" controls="" class="video audio-player" v-else-if="source.mimetype.startsWith('audio')" >
      <source :src="source|preview(policy)" :type="source.mimetype"/>
      Your browser does not support HTML5 video.
    </video>
    <img v-else :src="source|preview(policy)" />

    <div class="info text-left">
      <p class="filename">{{source.filename.substring(50)}}</p>
      <p class="stats">
        <span v-if="duration">{{duration|timestamp('H:m:ss')}} / </span>
        <span>{{source.size|magnitude}}B</span>
        <span class="float-right action" v-for="action in actions" @click="onMediaAction(action)"><b>{{action.label}}</b></span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MediaPlayer',
  data() {
    return {
      duration:0,
      paused:true,
      video: false
    }
  },
  props: {
    policy: {
      type: Object
    },
    source: {
      type: Object,
    },
    actions: {
      type: Array,
    }
  },
  created() {
    if ( this.source.mimetype.startsWith('image') ) return
    this.init()
  },
  methods: {
    init() {
      this.video = this.$refs['media-player']
      if ( this.video ) {
        this.video.addEventListener('canplay', this.canPlay);
      } else {
        setTimeout(this.init,100)
      }
    },
    canPlay() {
      this.duration = this.duration || this.video.duration
      console.log('computed duration',this.duration)
    },
    onPlayPause() {
      const player = this.$refs['media-player']
      if ( player.paused ) {
        player.play()
        this.paused = false
      } else {
        player.pause()
        this.paused = true
      }
    },
    onMediaAction(action) {
      this.$emit('on-media-action', action)
    }
  }
}
</script>

<style lang="scss" >

.media-player-wrapper {

    background-color: #F1F3F4;
    border-radius: 15px;
    padding: 10px;
    width: 400px!important;

    img {
      width: 90%;
    }

    .audio-player {
      height: 50px;
      width: 100%!important;
      color: lightgrey!important;
    }

    .video {
      width: 100%!important;      
      padding:0 !important;
    }

    .info {
      margin: 0 -10px -30px -10px;
      background-color: #F1F3F4;
      padding: 10px 10px 0px 10px;
      border-radius: 0px 0px 15px 15px;
      .filename {
        font-size: 10px;
        font-weight: bold;
        margin-bottom: 5px;
      }
      .stats {
        font-size: 10px;
        font-weight: normal;
        padding-bottom: 10px;
        span {
          padding-right:0px;
        }
        .action:hover {
          cursor:pointer;
          color: blue;
        }
      }
    }

    .playpause.paused {
        background-image:url(http://png-4.findicons.com/files/icons/2315/default_icon/256/media_play_pause_resume.png);
        background-repeat:no-repeat;
        width:50%;
        height:50%;
        position:absolute;
        left:0%;
        right:0%;
        top:0%;
        bottom:0%;
        margin:auto;
        background-size:contain;
        z-index: 999;
        background-position: center;
    }

    .playpause.playing.hover {
        background-image:url(http://png-4.findicons.com/files/icons/2315/default_icon/256/media_pause.png);
        background-repeat:no-repeat;
        width:50%;
        height:50%;
        position:absolute;
        left:0%;
        right:0%;
        top:0%;
        bottom:0%;
        margin:auto;
        background-size:contain;
        z-index: 999;
        background-position: center;
    }

  }

</style>
