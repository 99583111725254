<template>

    <div class="container-fluid list-view nomargin" v-if="policy">

      <!-- remove confirmation -->
      <ConfirmDialog :title="removeDialogPrompt" :click="completeRemove" :open="removeDialogOpen" />

      <!-- SchedulerDialog dialog -->
      <PostActionDialog  :show="showPostActionsDialog" :dashboard="dashboard" :content="content" :channels="selectedChannels" :selections="selectedContent" @on-action="onAction" />

      <!-- schedule a particular post -->
      <SchedulerDialog title="Schedule" :id="'scheduleContent'" v-if="scheduleContent" :post="scheduleContent" @on-action="onAction" />

      <!-- reschedule a particular post -->
      <SchedulerDialog title="Reschedule" :id="'rescheduleContent'" v-if="rescheduleContent" :post="rescheduleContent" @on-action="onAction" />

      <!-- header -->
      <div class="row header-container nopad">
        <div class="container header-content">
          <div class="row">
            <div class="col-12 nopad">
              <h2 class="msg">{{editing?'Edit Post': title }} 
                <span class="action-buttons float-right" v-if="editing">
                  <b-button variant="default" @click="toggleEdit()"> Cancel </b-button>
                  <b-button :disabled="(errors.length>0)" variant="primary" @click="toggleEdit(editing,1)"> Save </b-button>
                </span>
                <span class="refresh float-right" v-else-if="refreshTitle">
                  <small>{{refreshTitle}} </small>
                  <b-button variant="secondary" @click="poll()">  Refresh </b-button>
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>

      <!-- editor -->
      <div class="row editor" v-if="editing && session">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <Editor :post="editing" :policy="policy" :errors="errors" :session="session" :suppress="{actions:1}" :noSave="true" mode="inline" @update-schedule="handleUpdateSchedule" />
            </div>
          </div>
        </div>
      </div>

      <!-- list view -->
      <div class="row master" v-if="!editing">

        <b-overlay :show="busy">

        <div class="container nopad">

          <!-- content filter -->
          <div class="row nomargin">
            <div class="col-12 nopad filter-actions">
              <SelectFilterActions :config="config" :dashboard="dashboard" :content="content" :selections="selectedContent" :channels="selectedChannels" @on-select="onSelect" @on-filter="onFilter" @on-action="onAction" />
            </div>
          </div>

          <!-- list view -->
          <div class="row nomargin">

            <div class="col-12 nopad" v-if="content && !content.length">
              <h4>No results</h4>
            </div>

            <!-- items -->
            <div class="col-12 nopad" v-for="post in content" :key="post._id">
              <ListItem v-if="!editing" :post="post" :policy="policy" :selections="selectedContent" :sending="sending" @on-select="onSelect" @on-action="onAction" />
            </div>

          </div>

        </div>

      </b-overlay>

      </div>

      <!-- pagination -->
      <div class="row pagination nopad" v-if="content.length && !editing && paging.pages > 1">
        <div class="container">
          <div class="row">
            <div class="col-12 nopad">
              <Pagination :paging="paging" @on-nav="nav" />
            </div>
          </div>
        </div>
      </div>

    </div>

</template>


<style lang="scss">

.list-view {

  font-family: Noto Sans;

  .header-container {

    margin-top: -40px!important;

    background-color: #20C763;

    .header-content {
      color: #FFFFFF;
      background-color: #20C763;
      h2 {
        margin-top: 40px;
        margin-bottom: 40px;
        font-weight: bold;
        font-size: 40px;
      }
    }
  }

  .draft-item {
    padding: 0px;
    border: 1px solid lightgrey;
    margin-bottom: 40px;
    border-radius: 8px;
  }

  .action-buttons button {
    margin-left:10px;
  }

  .refresh small {
    font-size: 20px;
    margin-right: 10px;
  }

  .pagination {
    padding-top: 10px!important;
    button.disabled {
      border-width: 0px
    }
    .page-picker {
      padding-top: 4px!important;
    }
  }


}

</style>


<script>

import SelectFilterActions from '@/components/SelectFilterActions'
import SchedulerDialog from '@/components/SchedulerDialog'
import ListItem from '@/components/ContentView/ListItem'
import ConfirmDialog from '@/components/ConfirmDialog'
import Editor from '@/components/ContentView/Editor'
import PostActionDialog from './PostActionDialog'
import Pagination from '@/components/Pagination'


import { actions, getters } from '@/services/store'
import moment from 'moment'

export default {

  name: 'ContentPost.ListView',

  props: {

    title: {
      type: String
    },

    config: {
      type: Object
    },

    refreshTitle: {
      type: String
    }

  },

  data() {
    return {
      busy: false,
      status: '',
      errors: [],
      content: [],
      sending: [],
      publisher:'',
      session: false,
      editing: false,
      show:['D','F'],
      dashboard: false,
      timer: undefined,
      policy: undefined,
      showPostActionsDialog: false,
      selectedChannels: [],
      selectedContent: [],
      removeDialogPrompt:'',
      removeDialogOpen:false,
      showReschedule: false,
      scheduleContent: {},
      rescheduleContent: {},
      partner: getters.partner(),
      remainingFilters: [],
      updatedSchedule: null,
      paging: {
        skip: 0,
        page: 0,
        pages: 0,
        limit: 15,
        filter: '',
        sort: '-updatedAt'
      }
    }
  },

  async created() {
    this.policy = await actions.fetchPolicy()
    this.dashboard = getters.dashboard()
    this.publisher = getters.partner()
    this.status = this.config.filter.status.value.split(',')

    // list of filters enabled on page
    this.remainingFilters = Object.keys(this.config.filter||{}).filter((key)=>{
      return this.config.filter[key].editable
    })

  },

  destroyed() {
    if ( this.timer ) {
      this.clearTimeout(this.timer)
    }
  },

  computed: {
    pageList() {
      return Array.from({length: this.paging.pages}, (_, i) => i )
    },
  },

  methods: {

    refresh() {
      this.$router.go()
    },

    onFilter(ev) {

      const force = this.selectedChannels.length === 0
      switch( ev.key ) {
        case 'channels': {
          this.selectedChannels = ev.val
          break;
        }
        case 'status': {
          this.status = ev.val;
          break;
        }
      }

      // filters event on show - compute remaining
      if ( this.remainingFilters.includes(ev.key) ) {
        this.remainingFilters = this.remainingFilters.filter((f)=>{return f!==ev.key})
      } 

      // query when we have all filters reporting 
      if ( !this.remainingFilters.length ) {
        this.poll(true)
      }

    },

    onSelect(id) {
      if ( this.selectedContent.includes(id) ) {
        this.selectedContent = this.selectedContent.filter((s)=>s!==id)
      } else {
        this.selectedContent.push(id)
      }
    },

    removeAll() {
      this.removeDialogOpen = true
    },

    async completeRemove(confirm) {
      try {
        if ( confirm ) {
          this.busy = true
          const posts = this.selectedContent.length? this.selectedContent : this.content
          const ids = posts.map((p)=>{return (p._id||p)})
          await actions.removePosts(this.dashboard._id,ids)
          this.$toasted.success(`Removed ${posts.length} posts`)
          this.selectedContent = []
        }
      } catch( err ) {
        console.error(err)
        this.$toasted.error(`We were not able to remove your posts - please try again in a few minutes`)
      } finally {
        this.removeDialogOpen = false
        this.busy = false
        this.poll()
      }
    },

    handleUpdateSchedule(updatedSchedule) {
      this.updatedSchedule = updatedSchedule;
    },

    async savePost() {
      if (this.editing) {
        try {
          if (this.updatedSchedule) {
            this.editing.publishAt = moment(this.updatedSchedule.date).set({
              hour: moment(this.updatedSchedule.time.raw, 'HH:mm:ss').hours(),
              minute: moment(this.updatedSchedule.time.raw, 'HH:mm:ss').minutes(),
              second: 0
            }).toISOString();
            await actions.rescheduleContent(this.editing, this.editing.publishAt);
            this.$toasted.success('Schedule updated');
          }
          await actions.updatePost(this.session, this.editing);
          const idx = this.content.findIndex((h) => h._id === this.editing._id);
          if (idx !== -1) this.content.splice(idx, 1, this.editing);
          this.editing = false;
          this.session = false;
        } catch (err) {
          console.error('Failed to save post:', err);
          this.$toasted.error('Failed to save post');
        }
      }
    },

    async toggleEdit(post,save) {

      try {

        // lazily instantiante session for editor
        if ( !this.editing ) {

          if ( !this.session ) {

            this.session = await actions.findSession(post.genSourceV3)
            // session may have been deleted, if so create a mock session to support editor
            if ( !this.session ) {
              this.session = {
                _id: post.genSourceV3,
                dashboard: post.dashboard,
                config: {
                  steps: {
                    source: {}
                  }
                }
              }
            }
          }

          // editor operates on a clone
          if ( this.session ) {
            this.editing = JSON.parse(JSON.stringify(post))
          } else {
            this.$toasted.error('Unable to find session')
          }

        } else if ( save ) {
          await this.savePost();

        } else {
          this.editing = false
          this.session = false
        }

      } catch( err ) {
        this.$toasted.error(err.message)
      } finally {
        this.$emit('on-edit', this.editing )
      }

    },

    nav(page) {
      this.paging.page = page
      this.poll(true)
    },

    async pollImpl() {

      this.clearTimeout(this.timer)

      try {

        this.busy = true

        const filter = { channel: this.selectedChannels.map((ch)=>{return ch.channel}) }
        if ( this.status !== '*' ) filter.status = this.status

        const resp = await actions.fetchContent(this.dashboard._id, filter, this.paging)

        this.content = resp.content
        this.paging.pages = Math.ceil(resp.count/this.paging.limit)

        // find content awaiting processing
        const processing = this.content.filter((c)=>{
          return c.attachments && c.attachments.length && c.attachments.find((a)=>{
            return a.mimetype && (a.mimetype.startsWith('video') || a.mimetype.startsWith('audio')) && a.status && a.status !== 'completed' && a.status !=='failed' && a.status !== 'InTransit'
          })
        })

        if ( processing.length ) {
          this.poll()
        }

      } catch( err ) {
        console.error(err)
        this.$toasted.error(`We're not able to load your content just now; please try again in a few minutes`)
      } finally {
        this.busy = false
      }

    },

    clearTimeout() {
      if ( this.timer ) {
        clearTimeout(this.timer)
        this.timer = undefined
      }
    },

    async poll(force) {

      this.clearTimeout(this.timer)

      if ( force ) {
        this.pollImpl()
      } else {
        this.timer = setTimeout( this.pollImpl, (force? 0:5000) )
      }

    },

    async onAction(event) {

      try {

        const post = event.post? this.content.find((p)=>{return p._id === event.post._id}) : false
        // console.log('processing post', JSON.stringify(post,0,1))

        switch( event.action ) {

          case 'reprocess-video': {
            const resp = await actions.resubmitVideo( (post.dashboard._id||post.dashboard), post._id )
            post.attachments[0].status = 'pending_video_extraction'
            this.poll()
            break;
          }

          case 'clone': {
            const channels = Array.isArray(event.args.channel)? [event.args.channel.map((ch)=>{return ch.content.id})] : [event.args.channel.content.id]
            const resp = await actions.cloneContent( event.post.dashboard, event.post._id, channels )
            this.$toasted.success('Post cloned to 1 channel')
            if ( this.status.includes('D') ) {
              this.pollImpl(0)
            }
            break;
          }

          case 'schedule': {
            this.showPostActionsDialog = true
            break;
          }

          case 'reschedule': {
            this.rescheduleContent = post
            break;
          }

          case 'self-schedule': {
            this.scheduleContent = post
            break;
          }

          case 'scheduler-closed': {
            this.scheduleContent = {}
            this.rescheduleContent = {}
            break;
          }

          case 'schedule-cancel': {
            this.showPostActionsDialog = false
            break;
          }

          case 'schedule-complete': {
            this.showPostActionsDialog = false
            this.selectedContent = []
            this.poll()
            break;
          }

          case 'remove': {

            if ( post ) {
              await actions.removePost(post.dashboard,post)
              this.content.splice(this.content.indexOf(post),1)
              this.$toasted.success('Post removed')
            }

            else if ( this.selectedContent.length ) {
              this.removeDialogPrompt = `Delete ${this.selectedContent.length} Selections?`
              this.removeDialogOpen = true
            }

            else if ( this.content.length ) {
              this.removeDialogPrompt = `Delete ${this.content.length} Posts?`
              this.removeDialogOpen = true
            }

            break;
          }

          case 'send': {

            if ( post ) {

              try {

                // indicate send status
                this.sending.push(post._id)

                const resp = await actions.resendTo(post)
                if ( resp.status === 200 ) {
                  post.status = resp.data.status
                  post.publish_err = resp.data.publish_err
                  this.$toasted.success(`Post sent`)
                }

                this.poll()

              } catch(err) {
                this.$toasted.error(`We were unable to send that Post : ${err.message}`)
              } finally {
                const idx = this.sending.indexOf(post._id)
                this.sending.splice(idx,1)
              }

              // console.log('resend returned',resp.data)
            }
            break;
          }

          case 'edit': {
            this.toggleEdit(post)
            break;
          }

        }

      } catch ( err ) {

        console.error(err)
        let msg = err.message? err.message :  `We were unable to ${event.action} that Post - please try again in a few minutes. `
        this.$toasted.error(msg)

      }
    }

  },

  components: {
    SelectFilterActions,
    PostActionDialog,
    SchedulerDialog,
    ConfirmDialog,
    Pagination,
    ListItem,
    Editor
  }

}
</script>
