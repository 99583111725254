<template>
  <div class="container" >

    <div class="row">

      <div class="col-md-12">
        <h2 class="msg">
          Dashboard
        </h2>
        <br/>
      </div>

      <div class="col-md-9">

        <b-container class="mx-0 px-0">

          <b-row >

            <b-col >

              <!-- TODO - fold this into existing Keywords component -->
              <div class="dashboard-section">
                <h5>Top 10 Keywords
                  <small class="float-right keyword-action">
                    <router-link :to="{ name:'Messaging', dashboardId: dashboard._id}" href="#">
                      <i v-b-tooltip.hover title="Edit Keywords" class="fa fa-cog fa-lg" />
                    </router-link>
                  </small>
                </h5>
                <div class="row">
                  <div class="col-md-6">
                    <ol class="keyword-list">
                      <li v-for="(km,idx) in keyMessages" v-if="idx<5" :key="km._id">{{km.message}}</li>
                    </ol>
                  </div>
                  <div class="col-md-6">
                    <ol class="keyword-list" start="6">
                      <li v-for="(km,idx) in keyMessages" v-if="idx>5&&idx<=10" :key="km._id">{{km.message}}</li>
                    </ol>
                  </div>
                </div>
              </div>
            </b-col>

            <b-col v-if="hashTags.length">

              <!-- TODO - fold this into existing Keywords component -->
              <div class="dashboard-section">
                <h5>Top 10 Hashtags
                  <small class="float-right keyword-action">
                    <router-link :to="{ name:'Messaging', dashboardId: dashboard._id}" href="#">
                      <i v-b-tooltip.hover title="Edit Hashtags" class="fa fa-cog fa-lg" />
                    </router-link>
                  </small>
                </h5>
                <div class="row">
                  <div class="col-md-6">
                    <ol class="keyword-list">
                      <li v-for="(km,idx) in hashTags" v-if="idx<5" :key="km._id">#{{km.message}}</li>
                    </ol>
                  </div>
                  <div class="col-md-6">
                    <ol class="keyword-list" start="6">
                      <li v-for="(km,idx) in hashTags" v-if="idx>5&&idx<=10" :key="km._id">#{{km.message}}</li>
                    </ol>
                  </div>
                </div>
              </div>

            </b-col>

          </b-row>

        </b-container>

        <br/>
        <div class="row">
          <div class="col-md-12">
            <SessionHistory :dashboard="dashboard" />
          </div>
        </div>

      </div>
      <div class="col-md-3">
        <div class="dashboard-section">
          <GoalsView :dashboard="dashboard" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>

'use strict'

import { getters, actions } from '@/services/store'

import SessionHistory from '../SessionHistory'
import GoalsView from './GoalsView'

export default {

  name: 'Dashboard',

  data() {
    return {
      hashTags:[],
      dashboard: false,
      keyMessages:false,
    }
  },

  async created() {

    const resp = await actions.fetchKeyMessages()
    this.keyMessages = resp && resp.messages? resp.messages.filter((m)=>{return m.key==='Keywords'}) : []
    this.hashTags = resp && resp.messages? resp.messages.filter((m)=>{return m.key==='Hashtags'}) : []
    this.dashboard = getters.dashboard()

  },

  components: {
    SessionHistory,
    GoalsView
  }

}
</script>

<style>

.channel-count {
  padding-right: 10px;
}

ol.keyword-list {
  padding-left:20px;
}

.keyword-action {
  font-size: 0.6em;
  padding-top:8px;
}

.dashboard-section {
  padding: 15px;
  border: 1px solid lightgrey;
  border-radius:10px!important;
  background-color: #fff;
}

.dashboard-section h5 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.9rem;
}

.goal-trackers .goal-feature .goal-detail {
  font-size: 12px;
}

.dash-progress {
  background-color:lightgrey;
  margin-top: 10px;

}

.twitter-progress .progress-bar {
  background-color: #4099FF!important;
}

.facebook-progress .progress-bar {
  background-color: #3b5998!important;
}

.linkedin-progress .progress-bar {
  background-color: #0077b5!important;
}

.instagram-progress .progress-bar {
  background-color: #222222!important;
}

</style>
