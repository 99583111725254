<template>

  <b-modal id="plan-comparison" size="xl" static hide-header hide-footer content-class="plan-comparison">

    <template #default="{ hide }">
        <div class="heading">
          <div class="text-center">
            <span>Plan Comparison</span>
          </div>
          <div class="features">        
            <ul class="list-unstyled">
              <li v-for="row in rows">
                <TextRow v-if="row.type==='text'" :row="row" />
                <CheckRow v-else :row="row" />              
              </li>
            </ul>
          </div>
        </div>

    </template>

  </b-modal>

</template>

<script>

import TextRow from './TextRow'
import CheckRow from './CheckRow' 

export default {
  name: 'PlanComparison',
  data() {
    return {
      rows: [{
        type: 'text',
        class: 'banner',
        cols: ['','Lite/Starter/Prof','Enterprise'],
        border: 'solid lightgrey 1px',
        radius: '30px 30px 0px 0px',
      },
      
      {
        type: 'text',
        class: 'banner',
        cols: ['Social Post Generator'],
        border: 'solid lightgrey 1px',
        radius: '0px 0px 0px 0px',        
      },{
        type: 'check',
        cols: ['Generate from longform text, audio & video','y','y'],     
      },{
        type: 'check',
        cols: ['Generate from Prompt','y','y'],     
      },{
        type: 'check',
        cols: ['Generate from articles related to your industry','y','y'],     
      },{
        type: 'check',
        cols: ['Generate from RSS feeds','n','y'],     
      },{
        type: 'check',
        cols: ['Channel-targeted results: Facebook Business, Instagram Business, LinkedIn Personal, LinkedIn Business, X','y','y'],     
      },{
        type: 'check',
        cols: ['Add intro & outro videos and images to video clips','y','y'],     
      },{
        type: 'check',
        cols: ['Video & audio clip captioning','y','y'],     
      },{
        type: 'check',
        cols: ['Video & audio transcript editing','n','y'],     
      },{
        type: 'check',
        cols: ['Advanced video generation','n','y'],     
      },{
        type: 'text',
        cols: ['AI-recommended hashtags','Starter','Advanced'],     
      },{
        type: 'text',
        cols: ['AI-recommended rewrites, a.k.a. "style transfer" in multiple voices (brand or employee)','Starter','Advanced'],     
      },{
        type: 'text',
        cols: ['AI-optimized brand voice learning','Starter','Advanced'],     
      },{
        type: 'check',
        cols: ['AI "boosting" via back catalog voice learning','n','y'],     
      },
      
      {
        type: 'text',
        class: 'banner',
        cols: ['Analytics'],
        border: 'solid lightgrey 1px',
        radius: '0px 0px 0px 0px',        
      },{
        type: 'check',
        cols: ['AI-powered topic recommendations','n','y'],     
      },{
        type: 'check',
        cols: ['AI-optimized top time and day analysis','n','y'],     
      },{
        type: 'check',
        cols: ['Campaign comparison and performance tracking','n','y'],     
      },{
        type: 'check',
        cols: ['UTM-code tracking','n','y'],     
      },{
        type: 'check',
        cols: ['Social post component analysis (text, link, attachment)','n','y'],     
      },{
        type: 'check',
        cols: ['Organic influencer performance comparison (currently available for X)','n','y'],     
      },{
        type: 'check',
        cols: ['Channel performance comparison','n','y'],     
      },{
        type: 'check',
        cols: ['Author performance comparison','n','y'],     
      },{
        type: 'check',
        cols: ['Competitor keyword analysis','n','y'],     
      },
      
      {
        type: 'text',
        class: 'banner',
        cols: ['Scheduling & Publishing'],
        border: 'solid lightgrey 1px',
        radius: '0px 0px 0px 0px',        
      },{
        type: 'check',
        cols: ['AI-optimized scheduling and calendars','n','y'],     
      },{
        type: 'check',
        cols: ['AI-powered employee advocacy and social selling tools','n','y'],     
      },{
        type: 'check',
        cols: ['Automatic drip-feed scheduler with recommended cadence','n','y'],     
      },{
        type: 'check',
        cols: ['Multi-channel governance','n','y'],     
      },{
        type: 'check',
        cols: ['Approval workflows','n','y'],     
      },{
        type: 'text',
        cols: ['Publish with Lately, Hootsuite, Sprinklr or HubSpot','Starter','Advanced'],     
      },
      
      {
        type: 'text',
        class: 'banner',
        cols: ['Management'],
        border: 'solid lightgrey 1px',
        radius: '0px 0px 0px 0px',        
      },{
        type: 'text',
        cols: ['Brand/employee voice-learning','Starter','Unlimited'],     
      },{
        type: 'check',
        cols: ['Team/client controls','n','y'],     
      },{
        type: 'check',
        cols: ['Multiple account governance','n','y'],     
      },{
        type: 'check',
        cols: ['Single sign-on','n','y'],     
      },{
        type: 'text',
        cols: ['Users','Starter','Unlimited'],     
      },{
        type: 'text',
        cols: ['Connected social accounts','4,10,Unlimited','Unlimited'],     
      },
      
      {
        type: 'text',
        class: 'banner',
        cols: ['Storage'],
        border: 'solid lightgrey 1px',
        radius: '0px 0px 0px 0px',        
      },{
        type: 'text',
        cols: ['Sources "Generated From" Library','Starter','Unlimited'],     
      },{
        type: 'check',
        cols: ['Media Library','n','y'],     
      },{
        type: 'check',
        cols: ['Key Messaging Library','n','y'],     
      },
      
      {
        type: 'text',
        class: 'banner',
        cols: ['Customer Care'],
        border: 'solid lightgrey 1px',
        radius: '0px 0px 0px 0px',        
      },{
        type: 'check',
        cols: ['Chat only/48-hour response','y','n'],     
      },{
        type: 'check',
        cols: ['VIP AI training and education services','n','y'],     
      },{
        type: 'check',
        cols: ['Dedicated Customer Success Manager','n','y'],     
        last: true
      }]
    }
  },
  components: {
    CheckRow,
    TextRow
  }
}
</script>

<style lang="scss" >

.plan-comparison {
  border-radius: 20px;
  .modal-body {
  margin-top: -50px;
  }
  .header {
    padding: 20px;
    margin-bottom: 15px;
    text-align: center;
    span {
      color:#174C43;;
      font-weight: bold;      
      font-size:36px!important;
      font-weight:800;
    }
  }
}

</style>
