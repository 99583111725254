<template>
  <div class="calendar container-fluid nopad" v-if="dashboard && policy">

    <!-- 
      TODO - header should be refactored to be common among list view etc
    -->
    <div class="calendar-header">
      <div class="row nopad nomargin">
        <div class="container header-content nopad">      
          <h2>Outbox</h2>
        </div>
      </div>
    </div>

    <div class="calendar-body container nopad">
    <!-- remove confirmation -->
    <ConfirmDialog :title="removeDialogPrompt" :click="completeRemove" :open="removeDialogOpen" />

    <SelectFilterActions :config="config" :dashboard="dashboard" :content="content" :selections="selectedContent"
      :channels="selectedChannels" @on-select="onSelect" @on-filter="onFilter" />

    <FullCalendar :options="calendarOptions" ref="fullCalendar" />

    <!-- editor -->
    <EditorModal ref="editorModal" v-model="isEditorModalOpen" :session="session" :policy="policy" :errors="errors"
      :post="editing" @close-modal="handleCloseModal" @save-post="toggleEdit(editing, true)"
      @update-schedule="handleUpdateSchedule" @post-deleted="confirmDeletePost" />
  </div>
  </div>
</template>

<style scoped>
.calendar.container-fluid {

  font-family: Noto Sans;

  .calendar-header {

    margin-top: -40px!important;

    background-color: #20C763;    

    h2 {
      font-size: 40px;
      font-weight: bold;
      margin: 40px 0px 40px 0px;
    }
    background-color: #20C763;
    color: white;
  }

  .fc-button-active {
    background-color: #42b983;
    border: 0px;
  }
}

</style>


<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import SelectFilterActions from '@/components/SelectFilterActions'
import ConfirmDialog from '@/components/ConfirmDialog'

import { actions, getters } from '@/services/store'
import moment from 'moment'
import EditorModal from './EditorModal.vue'

export default {
  components: {
    EditorModal,
    FullCalendar,
    SelectFilterActions,
    ConfirmDialog
  },

  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        editable: true,
        eventStartEditable: true,
        eventDurationEditable: false,
        eventResizableFromStart: false,
        headerToolbar: {
          start: 'prev,next today',
          center: 'title',
          end: 'dayGridMonth,timeGridWeek,dayGridDay',
        },
        events: [],
        eventContent: this.renderEventContent,
        eventClick: this.handleEventClick,
        eventDrop: this.handleEventDrop,
        datesSet: this.handleDatesSet,
        allDaySlot: false,
        buttonText: {
          today:    'Today',
          month:    'Month',
          week:     'Week',
          day:      'Day',
        }        
      },
      config: {
        filter: {
          channels: {
            value: '*',
            editable: true,
          },
          status: {
            value: 'T,P,S,F',
            editable: true,
          },
        },
        actions: [],
      },
      editing: undefined,
      session: undefined,
      content: [],
      errors: [],
      isEditorModalOpen: false,
      dashboard: undefined,
      status: '',
      selectedContent: [],
      selectedChannels: [],
      removeDialogPrompt:'',
      removeDialogOpen:false,
      updatedSchedule: null,
      policy: undefined,
      paging: {
        skip: 0,
        page: 0,
        pages: 0,
        limit: 500,
        filter: '',
        sort: '-publishAt',
      },
    }
  },
  async created() {

    try {
        this.policy = await actions.fetchPolicy()
        this.dashboard = getters.dashboard()      
        this.status = this.config.filter.status.value.split(',')
        this.selectedChannels = this.dashboard.channels || []
      } catch (err) {
        console.error('Failed to initialize config:', err.message)
      }

    await this.fetchEvents()

  },
  methods: {

    async fetchEvents(fetchInfo = null, successCallback = () => { }, failureCallback = () => { }) {
      try {
        const filter = { channel: this.selectedChannels.map((ch) => ch.channel) }
        if (this.status !== '*') filter.status = this.status

        // paging is not relevant, use a fixed limit of 500 
        const response = await actions.fetchContent(this.dashboard._id, filter, {limit:500})
        this.content = response.content

        const events = await this.content.map((post) => {
          return {
            id: post._id,
            title: post.content,
            start: moment(post.publishAt).toISOString(),
            extendedProps: {
              logo: post.channel_meta ? post.channel_meta.logo : null,
              contentType: post.contentType,
              status: post.status
            },
          }
        })
        successCallback(events)
      } catch (err) {
        console.error('Failed to fetch content:', err.message)
        failureCallback(err)
      }
    },

    onFilter(ev) {
      const force = this.selectedChannels.length === 0
      switch (ev.key) {
        case 'channels': {
          this.selectedChannels = ev.val
          // Fetch events for selected channels
          this.fetchEvents(null, this.updateEvents, (err) => {
            console.error('Failed to fetch events for selected channels:', err)
          })
          break
        }
        case 'status': {
          this.status = ev.val
          this.fetchEvents(null, this.updateEvents, (err) => {
            console.error('Failed to fetch events for selected channels:', err)
          })
          break
        }
      }
    },

    async savePost() {
      if (this.editing) {
        try {
          if (this.updatedSchedule) {
            this.editing.publishAt = moment(this.updatedSchedule.date)
              .set({
                hour: moment(this.updatedSchedule.time.raw, 'HH:mm:ss').hours(),
                minute: moment(this.updatedSchedule.time.raw, 'HH:mm:ss').minutes(),
                second: 0,
              })
              .toISOString()
            await actions.rescheduleContent(this.editing, this.editing.publishAt)
            this.$toasted.success('Schedule updated')
          }
          await actions.updatePost(this.session, this.editing)
          const idx = this.content.findIndex((h) => h._id === this.editing._id)
          if (idx !== -1) this.content.splice(idx, 1, this.editing)

          this.updateCalendarEvents()
        } catch (err) {
          console.error('Failed to save post:', err)
          this.$toasted.error('Failed to save post')
        } finally {
          // Reset editing state and data
          this.resetEditingState();
        }
      }
    },

    resetEditingState() {
      this.editing = undefined;
      this.session = undefined;
      this.updatedSchedule = null;
    },

    updateCalendarEvents() {
      // Clear the existing events from the calendar
      this.$refs.fullCalendar.getApi().removeAllEvents();

      // Re-fetch events from your content or backend
      const events = this.content.map((post) => {
        return {
          id: post._id,
          title: post.content,
          start: moment(post.publishAt).toISOString(),
          extendedProps: {
            logo: post.channel_meta ? post.channel_meta.logo : null,
            contentType: post.contentType,
          },
        };
      });

      // Add the updated events to the calendar
      this.$refs.fullCalendar.getApi().addEventSource(events);
    },

    toggleEdit(post, save) {
      try {
        if (!this.editing) {
          // Start editing: create a deep clone of the post
          // TODO - use structuredClone() when we are at node v18 or better
          this.editing = JSON.parse(JSON.stringify(post))
        } else if (save) {
          // Save edits
          this.savePost()
        } else {
          // Cancel editing
          this.resetEditingState();
        }
      } catch (err) {
        this.$toasted.error(err.message)
      } finally {
        this.$emit('on-edit', this.editing)
      }
    },

    async handleEventDrop(info) {
      const event = info.event
      const newDate = moment(event.start).toDate();      
      const post = this.content.find((item) => item._id === event.id)

      // prevent rescheduling of published content
      if (  event._def.extendedProps.status === 'P' ) {
        this.$toasted.error('Event has already published')
        info.revert()        
      } 
      
      // prevent rescheduling into the past
      else if ( newDate < new Date() ) {
        this.$toasted.error('Schedule date is in the past')
        info.revert()
      } 
      
      // otherwise
      else if (post) {
        await this.ensureSessionForPost(post);

        // Get the updated date and time

        const newTimeRaw = moment(event.start).format('HH:mm:ss');
        const newTimeFormatted = moment(event.start).format('h:mm a');

        // Update the post's publishAt with the new date and time
        post.publishAt = moment(event.start).toISOString();

        // Update editing and updatedSchedule objects
        this.editing = post;
        this.updatedSchedule = {
          date: newDate,
          time: {
            raw: newTimeRaw,
            formatted: newTimeFormatted
          }
        };
        this.toggleEdit(post, true) // Save the new date
      }
    },

    /**
     * TODO - is there a more Vue centric way to do this that would allow
     * interpolation of properties etc? 
     * @param {} info 
     */

    renderEventContent(info) {
      const iconHTML = this.getIconHTML(info.event.extendedProps.contentType);

      return {
        html: `
                  <div style="
                    background: #999;
                    border-color: #999;
                    text-decoration: none;
                    margin-top: 2px;
                    padding: 5px 0px;
                    color: #1c2d33 !important;
                    width: 100%">
                    <div style="
                      background-color: #f2f2f2;
                      border: 1px solid #ccc;
                      border-radius: 4px;
                      padding: 5px;
                      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);">
                      <div style="
                        display: flex;
                        align-items: center;
                        justify-content: space-between;">
                        <p style="
                          margin: 0 !important;
                          font-size: 12px;
                          font-weight:bold;
                          color: #1c2d33;">
                          ${moment(info.event.start).format('h:mm A')}
                        </p>
                        <div style="display: flex;
                        align-items: center; gap:3px;">
                            <img src="${info.event.extendedProps.logo}" alt="" style="border-radius: 100%; width:20px; height:20px;">
                            ${iconHTML}
                        </div>
                      </div>
                      <h6 style="
                        font-size: 10px;
                        font-weight: normal;
                        color: #1c2d33; 
                        text-wrap:wrap;
                        margin-top:5px;">
                        ${info.event.title.substring(0, 70)}...
                      </h6>
                    </div>
                  </div>
                `,
      };
    },

    getIconHTML(contentType) {
      const icons = {
        twitter: '<i class="fab fa-square-x-twitter fa-lg"></i>',
        facebook: '<i class="fab fa-facebook fa-lg"></i>',
        linkedin: '<i class="fab fa-linkedin fa-lg"></i>',
        instagram: '<i class="fab fa-instagram fa-lg"></i>',
      };
      return icons[contentType] || '';
    },

    onSelect(id) {
      if (this.selectedContent.includes(id)) {
        this.selectedContent = this.selectedContent.filter((s) => s !== id)
      } else {
        this.selectedContent.push(id)
      }
    },

    confirmDeletePost() {
      this.removeDialogPrompt = `Are you sure you want to delete this post?`;
      this.removeDialogOpen = true;
    },

    async completeRemove(confirm) {
      if (confirm) {
        try {
          if (this.editing) {
            await actions.removePosts(this.dashboard._id, [this.editing._id])
            this.$toasted.success('Post deleted')

            // Remove post from content and update calendar
            this.content = this.content.filter(post => post._id !== this.editing._id)
            this.updateCalendarEvents()

            this.editing = undefined
            this.isEditorModalOpen = false
          }
        } catch (err) {
          console.error('Failed to delete post:', err)
          this.$toasted.error('Failed to delete post')
        } finally {
          this.removeDialogOpen = false;
        }
      } else {
        this.removeDialogOpen = false;
      }
    },

    async ensureSessionForPost(post) {
      if (!this.session || this.session._id !== post.genSourceV3) {

        console.log('ensureSession')
        this.session = await actions.findSession(post.genSourceV3);

        // In case the session is missing, create a mock session to support the editor
        if (!this.session) {
          this.session = {
            _id: post.genSourceV3,
            dashboard: post.dashboard,
            config: {
              steps: {
                source: {},
              },
            },
          };
        }
      }
    },

    async handleEventClick(info) {

      // Now match against `this.content` based on this ID
      const post = await this.content.find((item) => item._id === info.event.id)
      if (!post) return;

      await this.ensureSessionForPost(post)

      this.toggleEdit(post)

      // Ensure modal opens only after data is set
      this.isEditorModalOpen = true;
      this.$nextTick(() => {
        this.$refs.editorModal.showModal();
      });
    },

    handleUpdateSchedule(updatedSchedule) {
      this.updatedSchedule = updatedSchedule;
    },

    handleCloseModal() {
      this.toggleEdit(this.editContent, false);
      this.isEditorModalOpen = false
      this.editing = undefined
      this.updatedSchedule = null
    },

    handleDatesSet(arg) {
      this.fetchEvents(arg, this.updateEvents, () => {
        console.error('Failed to update events')
      })
    },

    updateEvents(events) {
      this.calendarOptions.events = events
    },
  },
}
</script>

