<template>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h3>Admin Portal</h3>
      <hr>
      <b-tabs pills card >
        <b-tab title="Analytics" lazy>
          <Analytics />
        </b-tab>
        <b-tab title="Products" lazy active>
          <Products />
        </b-tab>
        <b-tab title="Users" lazy>
           <Users />
        </b-tab>

      </b-tabs>
    </div>
  </div>
</div>
</template>

<script>

'use strict'

import { actions } from '@/services/store';

import Analytics from './Analytics';
import Products from './Products';
import Users from './Users';

export default {
  name: 'AdminPanel',
  components: {
    Products,
    Users,
    Analytics
  }
}
</script>

<style lang="scss" >

.admin-section-heading {
  vertical-align:middle;
  padding:5px;
}

.admin-section-heading button {
  margin:5px;
}

.admin-subsection-heading {
  padding:5px;
  margin:5px;
}

.admin-section-subheading button {
  margin:5px;
}

</style>
