<template>

  <div class="container-fluid drafts-view nomargin nopad">
    <ListView :title="partner==='lately'? 'Scheduler':'Drafts'" :config="config" :refresh-title="`Some Drafts may still be in progress`">
      <template v-slot:filter-actions>
        <span>Filter and Actions</span>
      </template>
    </ListView>
  </div>

</template>

<script>

import ListView from '@/components/ContentView/ListView'

export default {

  name: 'SchedulerView',

  data() {
    return {
      partner:'',
      config: {
        actions: [{
          id:'remove',
          partner: '*',
          float: 'right',
          title: {
            prefix: 'DELETE',
            suffix: 'DRAFTS',
          },
          variant:'outline-secondary'
        },{
          id:'schedule',
          float:'right',
          cls: 'primary',
          variant:'primary',
          partner:'lately',
          title: {
            prefix: 'SCHEDULE',
            suffix: 'DRAFTS',
            float: 'right',

          },
        }],
        filter: {
          channels: {
            value:'*',
            editable:true
          },
          status:{
            value: 'D',
            editable:false,
            checked:true
          }
        }
      }
    }
  },

  created() {
    this.partner==='lately'? 'Scheduler':'Drafts'
  },

  components: {
    ListView
  }

}
</script>

