<template>

  <div class="scheduler" :key="post._id">

    <div class="row text-left">

      <div class="col-md-3">

        <b-form>

          <b-form-group class="date-picker">
            <label><b>Select a Date:</b></label>
            <b-form-datepicker v-model="schedule.date" :min="minDate" locale="en"></b-form-datepicker>
          </b-form-group>

          <b-form-group class="time-picker">
            <label><b>Select a Time:</b></label>
            <b-input-group class="mb-3">
              <b-input-group-prepend>
                <b-form-timepicker hour12
                  v-model="schedule.time.raw"
                  button-only
                  left
                  locale="en"
                  aria-controls="example-input"
                ></b-form-timepicker>
              </b-input-group-prepend>
              <b-form-input
                id="example-input"
                v-model="schedule.time.formatted"
                type="text"
                placeholder="hh:mm a"
              ></b-form-input>

            </b-input-group>
          </b-form-group>

        </b-form>

        <div class="text-left post-preview" v-if="post && policy">
          <p class="info">Here's what we're currently scheduling:</p>
          <Preview class="preview" :post="post" :policy="policy" :border="true" />
        </div>

      </div>

      <div class="col-md-9 text-center">

        <vue-cal

          twelveHour
          ref="vueCal"
          today-button
          :min-date="minDate"
          events-count-on-year-view
          class="vuecal--green-theme"
          :selected-date="selectedDate"
          @view-change="viewChange"
          @cell-click="cellClick"
          active-view="week"
          :events="events"
          :locale="locale"
          :editableEvents="editActions"
          :time-cell-height="timeCellHeight"
          @ready="scrollTo"
          @event-drop="drop"
          @event-change="change"
          :disable-views="['years','day']" >

          <template #events-count="{ events, view }">
            <span class="events-count">
              {{ events.length }}
            </span>
          </template>

          <template #event="{ event, view }">
            <div :id="event._id" class="custom-event" v-on:mousedown="mousedown(event,$event)" v-on:mouseup="mouseup(event,$event)" v-on:dblclick="toggleEdit(event,$event)" mouseover="mouseover(event,$event)" mouseleave="mouseleave(event,$event)">
              <div class="summary" v-if="editing!==event">
                <i :class="event.icon" :style="{color:event.color}" />
                <span>{{event.title}}</span>
              </div>
              <div class="editor" v-else >
                <b-form @submit="submit(event,$event)">
                  <input :ref="'event_title_'+event._id" class="form-control" v-model="editing.model"  @keydown="keydown(event,$event)" />
                  <button type="submit" style="display:none" />
                </b-form>
              </div>
              <b-popover placement="right" :target="event._id" triggers="hover" delay="500">
                <div v-if="!editing&&!dragging">
                  <Preview :post="event.post" :policy="policy" :border="false" />
                </div>
              </b-popover>
            </div>
          </template>

        </vue-cal>

        <div class="scale">
          <b-form-input type="range" v-model="scale.current" :min="scale.min" :max="scale.max" step="1" style="width: 100%" />
          <p> Showing  {{this.scale.max-(this.scale.current-1)}} hours </p>
        </div>

        <p class="text-center message">
          <span class="text-danger" v-if="message">{{message}}</span>
        </p>

      </div>

    </div>

  </div>

</template>

<script>

import ChannelIcon from '@/components/ChannelIcon'
import Preview from '@/components/Preview'

import { media } from '@/services/constants'
import { actions } from '@/services/store'

import 'vue-cal/dist/vuecal.css'
import VueCal from 'vue-cal'
import moment from 'moment'


export default {

  name: 'Scheduler',

  props: {
    post: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: 'auto'
    }
  },

  data() {
    return {
      events:[],
      updates:[],
      message:'',
      view: 'week',
      now: moment(),
      scale: {
        min: 1,
        current: 9,
        max: 24
      },
      eventSource: '',
      showDialog: false,
      editing: false,
      dragging:false,
      selectedDate: false,
      selectedTime: false,
      timeCellHeight: 29.0625,
      minDate: new Date(),
      policy: false,
      schedule: {
        date: this.post.publishAt? moment(this.post.publishAt).toDate() : new Date(),
        time: {
          raw: this.post.publishAt? moment(this.post.publishAt).format('HH:mm:ss') : moment().format('HH:mm:ss'),
          formatted: this.post.publishAt? moment(this.post.publishAt).format('h:mm a') : moment().format('h:mm a')
        },
      },
      editActions: { title: false, drag: false, resize: false, delete: false, create: false },
      locale: {
        "weekDays": ["Mon", "Tues", "Weds", "Thurs", "Fri", "Sat", "Sun"],
        "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        "years": "Years",
        "year": "Year",
        "month": "Month",
        "week": "Week",
        "day": "Day",
        "today": "Today",
        "noEvent": "No Event",
        "allDay": "All day",
        "deleteEvent": "Delete",
        "createEvent": "Create an event",
        "dateFormat": "dddd D MMMM YYYY"
      }
    }
  },

  watch: {
    'scale.current'() {
      // console.log('scale changed to ', this.scale.current)
      // console.log('scale to ', this.scale.max-(this.scale.current-1), 'hours')
      this.onScale(this.scale.max-(this.scale.current-1))
    },
    'schedule.date'() {
      // console.log('schedule.date changed to', this.schedule.date,this.eventSource)
      this.selectedDate = moment(this.schedule.date).toDate()
      if ( !this.eventSource ) this.updateSelected()
    },
    'schedule.time.formatted'() {
      this.schedule.time.raw = moment(this.schedule.time.formatted,'h:mm a').startOf('minute').format('HH:mm:ss')
      this.updateSelected()
    },
    'schedule.time.raw'() {
      this.schedule.time.formatted = moment(this.schedule.time.raw,'HH:mm:ss').startOf('minute').format('h:mm a')
      this.updateSelected()
    },
    async selectedDate() {
      this.message = ''
      const sd = moment(this.selectedDate)
      this.schedule.date = sd.format('YYYY-MM-DD')
      this.updateSelected()
    }
  },

  methods: {

    updatePickers(start,source) {
      const publishAt = moment(start)
      this.schedule.date = publishAt.toDate()
      this.schedule.time.raw = publishAt.format('HH:mm:ss')
      this.schedule.time.formatted = publishAt.format('h:mm a')
    },

    updateSelected(emit) {

      const date = moment(this.schedule.date)
      const time = moment(this.schedule.time.raw,'HH:mm:ss')
      date.hours(time.hour())
      date.minutes(time.minute())
      date.seconds(0)
      this.addOrUpdateEvent(this.post,date,this.events)
      if ( emit ) {
        this.$emit('on-action', { action: scheduled, schedule: date.toDate() })
      }
    },

    mouseover(ev) {

    },

    mouseleave(ev) {
      this.dragging=false
    },

    mousedown(event,ev) {
      // console.log('mousedown',ev)
      this.dragging=event
    },

    mouseup(event,ev) {
      // console.log('mouseup',ev)
      this.dragging=false
    },

    keydown(event,e) {
      if ( e.which === 27 ) {
        this.toggleEdit(event,e)
      }
    },

    async doUpdate(event,start) {

      // TODO check for conflicts
      // console.log('doUpdate', event.post)

      try {
        await actions.rescheduleContent( event.post, moment(start,'YYYY-MM-DD HH:mm').toDate() )
        this.dragging = false
        this.editing = false
        this.$toasted.success('Post rescheduled')
      } catch( err ) {
        this.$toasted.error(err.message)
      }
    },

    submit(event,ev) {
      const start = moment(event.start)
      const time = moment(event.model,'h:mm a')
      start.hour(time.hour())
      start.minute(time.minute())
      start.seconds(0)
      // console.log('submit parsed time', time.hour(), time.minute(), start.toISOString())
      event = this.addOrUpdateEvent(event.post,start,this.events)
      this.doUpdate(event,event.start)
    },

    // prevent rescheduling of published items, or rescheduling into the past
    drop(ev) {

      this.dragging = false
      const post = ev.event.post
      const isPast = moment(ev.newDate).isBefore(moment())
      const entry = this.events.find((e)=>{return e.post._id === post._id})

      // prevent dropping into the past
      if ( isPast ) {
        this.message = 'Cannot schedule in the past'
        const idx = this.events.indexOf(entry)
        // console.log('resetting entry',idx)
        this.events.splice(idx,1)
        this.addOrUpdateEvent(entry.post, moment(ev.oldDate),this.events)
      }

    },

    change(ev,e) {
      console.log('change',ev.event)
      const event = ev.event
      const start = moment(event.start)
      if ( start.isBefore(moment()) ) return false
      const original = this.events.find((e)=>{return e._id===event._id})
      original.title = start.format('h:mm a')
      original.start = event.start
      original.model = start.format('h:mm a')  // for editor
      original.end = start.add(1,'minute').format('YYYY-MM-DD HH:mm')
      this.doUpdate(original,event.start)
      this.updatePickers(event.start)
    },

    scrollInfo() {
      const calendar = document.querySelector('.vuecal__bg')
      const result = calendar? {
        calendar: calendar,
        scrollHeight: calendar.scrollHeight,
        clientHeight: calendar.clientHeight,
        scrollTop: calendar.scrollTop
      } : false
      return result
    },

    onScale(hours) {
      const scrollInfo = this.scrollInfo()
      if ( scrollInfo ) {
        let relPos = scrollInfo.scrollTop/scrollInfo.scrollHeight
        let newCellHeight = scrollInfo.clientHeight / hours
        this.timeCellHeight = newCellHeight
        console.log('set timeCellHeight', this.timeCellHeight)
        setTimeout( ()=>{
          this.scrollTo(relPos)
        }, 100)
      }
    },

    scrollTo (relPos=0.5) {
      const scrollInfo = this.scrollInfo()
      if (scrollInfo) {
        const desired = scrollInfo.scrollHeight * relPos
        scrollInfo.calendar.scrollTo({ top: desired, behavior: 'smooth' })
      } else console.error('no calendar')
    },

    toggleEdit(event,e,save) {
      // console.log('toggleEdit')
      if ( this.editing === event ) {
        this.editing = false
      } else {
        this.editing = event
        setTimeout(()=>{
          const input = this.$refs['event_title_'+event._id]
          if ( input ) {
            input.focus()
            input.select()
          }
        },200)
      }
      if ( e )  {
        e.stopPropagation()
        e.preventDefault()
      }
    },

    reschedule() {
      // console.log('reschduling', this.editing)
    },

    eventClick (event, e) {
      // console.log('eventClick')
      e.stopPropagation()
    },

    miniCellClick(date) {
      // console.log('miniCellClick',date)
    },

    setSelected(date,source) {
      this.eventSource = source
    },

    cellClick(ev) {

      this.message = ''
      let date = ev.date || ev

      if (!moment(date).isAfter(moment())) return this.message = 'Selected date and time is in the past'

      this.eventSource = 'calendar'

      // calendar date & time clicked
      if ( ev instanceof Date ) {
        this.selectedDate = date
        this.updatePickers(date)
        this.addOrUpdateEvent(this.post,moment(date),this.events,true)
        this.$emit('on-action', { action:'scheduled', schedule: date } )
      }

      // otherwise date header clicked
      else {
        return console.log('date header click')
      }

    },

    // fired when
    async viewChange(ev) {
      this.view = ev.view
      if ( this.view === 'week' ) {
        await this.fetchSchedule(ev.startDate, ev.endDate)
        setTimeout( this.scrollTo, 400)
      }
    },

    colorFor(type) {
      return media[type]? media[type].color : 'lightgrey'
    },

    validateSelection(selectedDate) {

      const FORMAT = 'YYYY-MM-DD HH:mm' // to match entry.start format
      const formatted = moment(selectedDate).format(FORMAT)
      const contentType = this.post.contentType==='temporary'? this.post.channel : this.post.contentType
      const conflicts = this.events.filter((e)=>{
        return e.post._id !== this.post._id && e.post.contentType === contentType && e.start === formatted
      })

      if ( conflicts.length ) {
        this.message = `There are existing ${contentType} posts scheduled at this time - please make another selection`
        this.$emit('on-action',{action:'schedule-selected'})
      } else {
        this.message = ''
        this.$emit('on-action',{action:'schedule-selected',schedule:selectedDate})
      }
    },

    addOrUpdateEvent(e,publishAtMoment,list,selected) {

      const existing = list.find((ee)=>{return e._id === ee._id })
      publishAtMoment.subtract(1,'minute')

      if ( existing ) {
        list.splice(list.indexOf(existing),1)
        existing.start = publishAtMoment.format('YYYY-MM-DD HH:mm'),
        existing.end = publishAtMoment.add(1,'minute').format('YYYY-MM-DD HH:mm')
        existing.title = publishAtMoment.format('h:mm a')
        existing.model = existing.title
        this.validateSelection(publishAtMoment.toDate())
        list.push(existing)
        return existing
      } else {
        const type = e.contentType === 'temporary'? e.channel : e.contentType
        const icon = type === 'twitter'? 'square-x-twitter' : type
        const entry = {
          _id:e._id,
          post: e,
          hover: false,
          editing: false,
          draggable: e.status !== 'P',
          icon: `fab fa-${icon}`,
          color: this.colorFor(type),
          class: e._id===this.post._id? 'selected': type,
          title:publishAtMoment.format('h:mm a'),
          model:publishAtMoment.format('h:mm a'),
          start: publishAtMoment.format('YYYY-MM-DD HH:mm'),
          end: publishAtMoment.add(1,'minute').format('YYYY-MM-DD HH:mm')
        }
        this.validateSelection(publishAtMoment.toDate())
        list.push(entry)
        return entry
      }
    },

    async fetchSchedule(start,end) {

      try {

        this.ready = false

        const type = this.post.contentType? this.post.contentType : this.post.channel
        const from = start? moment(start) : moment(this.selectedDate).subtract(1,'day').startOf(this.view).add(1,'day')
        const to = end? moment(end) : moment(this.selectedDate).subtract(1,'day').endOf(this.view).add(1,'day')
        const rawEvents = await actions.fetchScheduledContent( type, from.format('YYYY-MM-DD'),to.format('YYYY-MM-DD') )

        // group by date
        this.events = rawEvents.reduce((acc,e)=>{
          const pa = moment(e.publishAt)
          this.addOrUpdateEvent(e,pa,acc)
          return acc
        },[])

        // re-inject post
        if ( this.post.publishAt ) {
          this.addOrUpdateEvent(this.post,moment(this.post.publishAt),this.events)
        }

      } catch( err ) {
        console.error(err)
        this.$toasted.error('We were not able to fetch your schedule - please try again in a few minutes')
        this.events = []
      } finally {
        this.ready = true
      }

    },
  },

  async created() {
    this.selectedDate = this.post.publishAt? moment(this.post.publishAt).toDate() : moment().toDate()
    this.policy = await actions.fetchPolicy()
    await this.fetchSchedule()
    this.scrollTo()
  },

  components: {
    ChannelIcon,
    Preview,
    VueCal
  }
}
</script>

<style lang="scss" >

.scheduler {

  .post-preview {
    p.info {
      font-size: 16px;
      font-weight: bold;
    }
    .preview {
      border: 1px solid lightgrey;
      border-radius: 10px;
    }
  }

  .time-picker .input-group-prepend {
    button {
      min-width: 40px!important;
      color: black;
      border: 1px solid lightgrey;
      background-color: white!important;
    }
  }

  .scale {
    margin-top: 20px;
    p {
      margin: 0px;
    }
  }

  .vuecal {

    height: 550px;

    .vuecal__cell-events-count {
      height: 20px;
      width: 20px;
      font-size: 12px;
      padding-top: 4px;
      margin-bottom: 2px;
    }

    .vuecal__event {
      margin-left: 3px;
      width: 95%!important;
      color: black;
      min-height: 25px;
      border-radius: 2px;
      background-color: white;
      border: 1px solid lightgrey;
    }

    .vuecal__event.selected {
      background-color: rgb(66, 185, 131);
      border: 1px solid rgb(66, 185, 131);
      color: white;
    }

    .custom-event {

      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      .summary {
        i {
          margin-right: 5px;
        }
      }

    }

    .custom-event:hover {
      max-height: 400px!important;
      max-width: 400px!important;
      background-color: lightgrey;
    }

  }

  .editor input {
    padding: 0px 2px 12px 2px;
    font-size: 16px;
    line-height: 12px;
  }

  .actions {
    font-size: 18px;
    padding: 20px;
    .action {
      padding: 15px;
    }

  }

}
</style>

rgb(66, 185, 131)
