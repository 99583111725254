<template>

  <div class="post-actions-v2 nopad">

    <!-- for exportly -->
    <small v-if="!includes('connect-third-party,connect-lately')" class="float-left">

      <b-button variant="link" size="sm" v-if="(session.outbox.length>0)" class="post-action-btn" :href="exportUrl('outbox')" @click="exported(session.outbox.length)">EXPORT {{session.outbox.length}} POST{{session.outbox.length>1? 'S':''}} </b-button>
      <b-button size="sm" v-if="content.length && includes('generate-export')" class="post-action-btn" variant="link"  :href="exportUrl('all')" @click="exported(session.postsRemaining.all)">EXPORT ALL POSTS</b-button>

    </small>

    <!-- Lately actions -->
    <span class="float-right" v-if="partner==='lately'">
      <b-button size="sm" class="post-action-btn trash" @click="sendTo('trash',post)" variant="outline-secondary"><i class="fa fa-trash-alt" /></b-button>
      <b-button size="sm" :disabled="(errors.length>0)" class="post-action-btn schedule" v-b-modal="'schedule-modal'">SCHEDULE</b-button>
    </span>

    <!-- others -->
    <span class="float-right" v-else>
      <b-button size="sm"  class="post-action-btn trash" @click="sendTo('trash',post)" variant="outline-secondary"><i class="fa fa-trash-alt" /></b-button>
      <b-button size="sm"  v-if="!includes('connect-third-party,connect-lately')" @click="sendTo('export',post)" class="post-action-btn" :disabled="(errors.length>0)" variant="outline-secondary">ADD TO EXPORT</b-button>
      <b-button size="sm" v-else :disabled="(errors.length>0)" class="post-action-btn schedule" v-b-modal="'schedule-modal'">SAVE</b-button>
    </span>

    <!-- previous
    <span class="float-right" v-else>
      <b-button size="sm"  v-if="!isIframe && includes('connect-third-party,connect-lately')" @click="sendTo('queue',post)" class="post-action-btn" :disabled="(errors.length>0)" variant="outline-secondary">SAVE TO DRAFTS</b-button>
      <b-button size="sm"  v-if="!includes('connect-third-party,connect-lately')" @click="sendTo('export',post)" class="post-action-btn" :disabled="(errors.length>0)" variant="outline-secondary">ADD TO EXPORT</b-button>
      <b-button size="sm"  @click="sendTo(partner,post,true)" v-if="includes('send-third-party,send-lately') && partner !== 'lately'" class="post-action-btn primary" :disabled="(errors.length>0)" variant="outline-light">SEND TO {{partner|partnerName|upperCase}}</b-button>
      <b-button size="sm"  @click="sendTo(partner,post,true)" v-if="includes('send-third-party,send-lately') && partner === 'lately'" class="post-action-btn primary" :disabled="(errors.length>0)" variant="outline-light">PUBLISH TO {{post.channel|upperCase}}</b-button>
    </span>
    -->

  </div>

</template>

<script>

import { includes } from '@/services/product'
import { store, getters } from '@/services/store'

export default {

  name: 'Actions',

  data() {
    return {
      isIframe:false,
      includes:includes,
      partner:''
    }
  },

  props: {
    content: {
      type:Array,
      required:true
    },
    session: Object,
    errors: Array,
    post: Object
  },

  created() {
    this.partner = getters.partner()
    this.isIframe = getters.isIframe('hootsuite')
  },

  methods: {

    token() {
      return store.token;
    },

    exportUrl(scope) {
      return `${getters.baseUrl()}content/${(this.session.dashboard._id||this.session.dashboard)}/${this.session._id}/export?scope=${scope}`
    },

    exported(count) {
      this.$emit('on-exported',count)
    },

    sendTo(dest,post,publish) {
      this.$emit('on-send-to',{
        dest:dest,
        post:post._id,
        publish:publish
      })
    }
  }
}
</script>

<style lang="scss" >

.post-actions-v2 {



  .post-action-btn {
    margin: 20px 0px 20px 10px;
    padding: 12px 20px 12px 20px;
    font-size: 12px;
    font-weight: bold;
    border: 2px solid #D2D9E2!important;
    border-radius:12px!important;
  }

  .post-action-btn.schedule {
    background-color: #008CFF!important;
    border: 2px solid #008CFF!important;
    min-width: 150px;
  }

}
</style>
