<template>

    <b-modal class="channel-select-modal" v-model="show" centered>

      <template #modal-header="">
        <div class="channel-modal-header">
          <h3>Which {{post.channel|channelname|titlecase}} accounts should we {{action()}} your post to?
          <p class="onboard-connect-channel-type">
          </p>
          </h3>
          <p>Choose one or more {{post.channel|channelname|titlecase}} accounts</p>
        </div>
      </template>

      <template #default="">

        <b-overlay :show="busy">

          <ChannelIcon v-show="!busy" class="modal-channel-select-icon" size="2x" style="position:absolute;top:-35px;right:40px;" :type="post.channel" mode="badge" />
          <div class="onboard-connect-channel" v-for="channel in needsSelection" :key="channel.content.id">
            <b-form-checkbox :disabled="!channel.authed" v-model="selections[channel.content.id]" @input="selected(channel)" name="check-button"> 
              {{channel.content.name}} 
              <small v-if="!channel.authed" class="text-danger">
                <RouterLink :to="{ name:'Publisher', dashboardId }">Please reconnect</RouterLink>
              </small>
            </b-form-checkbox>
          </div>

        </b-overlay>

      </template>

      <template #modal-footer="" >
    
          <p v-if="partner==='hootsuite' && sendToTarget!=='queue'" class="channel-select-footer">
            <small class="hootsuite-schedule">
            *Posts will be sent to the selected channels, and be scheduled for 1
            <b-dropdown class="hootsuite-schedule-picker" size="sm"  variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
              <span >{{hootsuite.schedule|titlecase}}</span>
              </template>
              <b-dropdown-item @click="setHootsuiteSchedule('day')">Day</b-dropdown-item>
              <b-dropdown-item @click="setHootsuiteSchedule('week')">Week</b-dropdown-item>
              <b-dropdown-item @click="setHootsuiteSchedule('month')">Month</b-dropdown-item>
            </b-dropdown> in advance. You can change the scheduled date and time in your Hootsuite account.
            </small>
          </p>
          <b-button :disabled="busy" @click="close('cancelled')" class="float-left" variant="primary">Cancel</b-button>
          <b-button @click="send()" :disabled="busy||!selectionsValid" class="float-right" variant="primary">Confirm and {{action()|titlecase}}</b-button>

      </template>
    </b-modal>

  </template>

  <script>

  import ChannelIcon from '@/components/ChannelIcon'
  import Session from '@/services/session'

  import { getters } from '@/services/store'

  export default {

    name: 'ChannelPicker',

    data() {
      return {
        busy:false,
        selections: {},
        dashboardId:undefined,
        selectionsValid:false,
        needsSelection: [],
        hootsuite: {
          schedule: Session.get('hootsuite-schedule','day')
        }
      }
    },

    props: {
      post: Object,
      show: Boolean,
      partner: String,
      dashboard: Object,
      schedule: String,
      sendToTarget: String
    },

    watch: {
      show() {

        this.busy = false

        // retain selections for similar channel type, clear others
        if ( this.show ) {
          this.needsSelection = this.dashboard.channels.filter((ch)=>{return ch && ch.content && ch.type === (this.post.channel||this.post.contentType)})
          this.selections = this.needsSelection.reduce((acc,ch)=>{
            if ( ch && ch.content ) {
            acc[ch.content.id] = this.needsSelection.find((ch2)=>{return ch.content && ch2.content && ch.content.id === ch2.content.id }) && this.selections[ch.content.id]
            }
            return acc
          }, {} )
        }

      }
    },

    created() {
      this.dashboardId = getters.dashboard()._id
    },

    methods: {

      action() {
        if ( this.partner === 'lately' && this.schedule ) {
          return 'schedule'
        } else {
          return this.sendToTarget === 'queue'? 'save' : 'send'
        }
      },

      async selected(channel) {
        this.selectionsValid = Object.keys(this.selections).reduce((acc,key)=>{
          if ( this.selections[key] ) acc.push(key)
          return acc
        },[]).length
      },

      close(method) {
        this.$emit('on-close',method)
      },
      send() {
        console.log('channelPicker.send',this.selections)
        this.busy = true
        this.$emit('on-send', {
          channels:this.selections,
          schedule:(this.schedule || this.hootsuite.schedule)
        })
      },
      setHootsuiteSchedule(interval) {
        this.hootsuite.schedule = interval;
        Session.set('hootsuite-schedule',interval)
      },

    },

    components: {
      ChannelIcon
    }

  }

  </script>


<style lang="scss" >

  .channel-select-modal {

    .hootsuite-schedule-picker button {
      margin-bottom:12px!important;
    }

    .channel-select-icon {
      position:absolute;
      top:-35px;
      right:40px;
    }

  }

  .channel-select-footer {
    button {
      background-color: #008CFF !important;
    }
  }

  </style>
