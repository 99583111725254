<template>

    <div class="signup-page account-info">

        <div class="row  overflow-hidden">
            <b-col class="col-8 d-flex flex-column align-items-center justify-content-start p-5">
                <b-col class="col-6">
                    <div class="w-100 mb-4 logo-container">
                        <img src="@/assets/lately_logo_full.jpg" alt="Lately Logo" class="logo-image" />
                    </div>
                    <h1 class="heading">Let's set up your Lately account!</h1>
                    <p class="lead">
                        Get access, a plan and results in Lately's 7-Day Bootcamp.
                        <br />
                        Cancel anytime!
                    </p>

                    <b-form @submit.prevent="login" action="#">
                        <b-form-group label="Email Address:" label-for="email">
                            <b-form-input id="email" autocomplete="email" autofocus v-model="user.email" type="email" placeholder="" :state="vemail" required></b-form-input>
                        </b-form-group>

                        <b-form-group label="Create Password:" label-for="password">
                            <b-form-input id="password" autocomplete="password" v-model="user.psswd" type="password" placeholder="" :state="vpassword" required></b-form-input>
                        </b-form-group>

                        <b-form-group label="Confirm Password:" label-for="confirm-password">
                            <b-form-input id="confirm-password" autocomplete="password" v-model="user.psswdconf" type="password" placeholder="" :state="vpasswordConf" required></b-form-input>
                        </b-form-group>

                        <div class="col-md-12" v-if="!isLocal">
                            <vue-recaptcha class="recaptcha" @verify="verify" :sitekey="recaptchaKey" :loadRecaptchaScript="true"></vue-recaptcha>
                        </div>

                        <div v-if="message" class="message w-100">
                            <p class="text-danger" v-if="message === 'exists-ss'">Please use another email address.</p>
                            <p class="text-danger" v-else-if="message === 'exists-lately'">That email address is associated with a Lately Enterprisely account. Please use another email address, or <a :href="'https://dashboard.lately.ai/login?email=' + user.email">Sign In to my Enterprisely Account</a></p>
                            <p class="text-danger" v-else>{{ message }}</p>
                        </div>

                        <div class="mt-4">
                            <b-button block :disabled="errors.length > 0" variant="success" @click="submit()">Sign Up</b-button>
                        </div>

                        <br />
                        <p class="mt-3 signin-link">
                            <span>
                                Already have a Lately account?
                                <a href="#/app/login"> Sign in.</a>
                            </span>
                        </p>
                    </b-form>
                </b-col>
            </b-col>

            <!-- social proof block -->
            <div class="col-4 offset-1 social-proof">
                <img src="../../assets/quote.svg" alt="quote" />
                <p>"I used Lately for the first time to <br />promote a story on opioids and it became <br />the most watched video on all of NBC <br />news websites for the month with 6x the <br />average views, putting it in the top 1% of <br />all NBC news videos viewed of the year."</p>
                <h1>Kate Snow</h1>
                <h6>National Correspondent, NBC</h6>
                <img src="../../assets/NBC_News_logo.png" alt="NBC_News_logo" class="invert-logo" />
            </div>
        </div>
    </div>
</template>

<script>
'use strict'

import VueRecaptcha from 'vue-recaptcha';

export default {

    name: 'Login',

    data() {
        return {
            user: {
                email: '',
                psswd: '',
                psswdconf: '',
                website: '',
                recaptcha: '',
            },
            errors: [],
            isLocal: true,
            recaptchaKey: ''            
        }
    },
    
    created() {
        this.isLocal = process.env['VUE_APP_LATELY_ENV'] === 'local'   
        if ( !this.isLocal ) {
            this.errors.push('recaptcha')
            this.recaptchaKey = process.env['VUE_APP_RECAPTCHA_KEY']
        }        
    },

    props: {
        message: {
            type: String,
            default: ''
        }
    },

    methods: {
        verify(token) {
            this.user.recaptcha = token
            this.mark('recaptcha', true)
        },
        mark(field, val) {
            const idx = this.errors.indexOf(field)
            if (idx == -1 && !val) this.errors.push(field)
            else if (val && idx >= 0) this.errors.splice(idx, 1)
            return val;
        },
        submit() {
            this.$emit('account-info-selected', this.user)
        }
    },

    computed: {
        vemail() {
            var re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            var constraints = {
                from: {
                    email: true
                }
            };
            return this.mark('email', (re.test(this.user.email)))
        },
        vpassword() {
            return this.mark('psswd', (typeof this.user.psswd == 'string' && this.user.psswd.length >= 8))
        },
        vpasswordConf() {
            return this.mark('psswdconf', (typeof this.user.psswd == 'string' && this.user.psswd.length >= 8 && this.user.psswdconf === this.user.psswd))
        },
    },

    components: {
        VueRecaptcha
    }

}
</script>

<style lang="scss">
.account-info {

    font-family: Noto Sans;

    padding: 10px;

    .logo-container {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .logo-image {
        max-width: 150px;
        height: auto;
    }

    .product-info {

        .context {}

        .title {
            margin-top: 20px;
            font-size: 26px;
            font-weight: bold;
            color: #2D2D2D
        }

        .description {
            margin-top: 10px;
            font-size: 22px !important;
            font-weight: bold;
            color: #2D2D2D
        }

        .banner {
            margin-top: 30px;
            color: #20C763;
            font-size: 32px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        ul {
            padding: 0px 24px 0px 24px;
            margin-bottom: 96px;
        }

        ul li {
            margin-bottom: 12px;
            font-size: 20px;
        }

        ul li .fas {
            margin-top: 8px;
            font-size: 12px;
            color: #20C763;
            margin-right: 12px;
        }

    }

    .account-form {

        background-color: white;
        border: 1px solid lightgrey;
        border-radius: 0px 0px 5px 5px;
        border-top: 5px solid #20C763;
        box-shadow: 2px 2px 2px lightgrey;
        margin: 30px 0px;

        .form-group {
            margin: 0px;
        }

        label {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        input {
            margin-bottom: 30px;
        }

        .recaptcha {
            transform: scale(1.15);
            -webkit-transform: scale(1.16);
            transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
            margin: 0px;
            width: 100%;
        }

        .message {
            margin: 20px 0px 10px 3px;

            a {
                color: #008CFF
            }
        }

        .actions {
            margin-top: 40px;

            button {
                font-size: 18px;
                font-weight: bold;
                color: white;
                border: 1px solid #20C763;
                border-radius: 8px;
                padding: 10px 30px 10px 30px;
                background-color: #20C763;
                margin-bottom: 30px;
            }
        }

        .heading {
            font-weight: bold;
            font-size: 28px;
            margin-top: 20px;
        }

        .back {
            margin-bottom: 40px;
            font-size: 15px;
            font-weight: regular;
            color: #2D2D2D;

            a {
                color: #008cff;
            }
        }
    }
}

.heading {
    font-size: 1.75rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 1rem;
}

.lead {
    font-size: 0.93rem;
    color: #555555;
    margin-bottom: 1.5rem;
}

.social-proof {
    background: #174c43 !important;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    right: 0px;
}

.social-proof img {
    width: 150px;
    height: auto;
    margin-bottom: 1rem;
}

.invert-logo {
    margin-top: 20px;
    filter: invert(1) brightness(1.5);
}

.social-proof p {
    color: white;
    text-align: center;
    margin-top: 30px;
}

.social-proof h1 {
    color: white;
    margin-top: 10px;
    font-size: 18px;
}

.social-proof h6 {
    color: white;
    font-size: 15px;
}

@media screen and (max-width: 1024px) {
    .social-proof {
        display: none;
    }
}
</style>
