
<template>

  <div :key="post._id" class="grammarly-edtor-v2">

    <Grammarly clientId="client_2uUW7bgYEuBKkdy4Q4f93P">
      <GrammarlyEditorPlugin :config="config">
        <textarea ref="post_editor_v2"
          v-model="post.content"
          @input="findPosition($event)"
          @keyup="findPosition($event)"
          @click="findPosition($event)"
        rows="6" />

        <div class="row row-content-preview"  v-if="post.preview_cache && post.preview_cache.title && !post.attachments.length">
          <div class="col-md-12">
            <Preview :post="post" :policy="policy" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12  row-content-status">
            <ContentStatus :post="post" :session="session" :policy="policy" :errors="errors" @on-toggle-emoji="onToggleEmoji" @attached="attached" @on-select-hashtag="onSelectHashtag" @on-select-keyword="onSelectKeyword"/>
          </div>
        </div>

      </GrammarlyEditorPlugin>
    </Grammarly>

  </div>

</template>

<script>

/**
Textarea with support for tracking current cursor position and inserting text at current position
Currently used for Emoji support
**/

'use strict'

import { Grammarly, GrammarlyEditorPlugin } from "@grammarly/editor-sdk-vue";

import { GrammarlyConfig } from '@/services/grammarly-config'

import ContentStatus from './ContentStatus'
import Preview from '../Preview'
export default {

  name: 'Editor',

  data() {
    return {
      config: GrammarlyConfig,
      useGrammarly: true,
      selectionStart: 0,
      selectionEnd:0
    }
  },

  props: {
    post: {
      type: Object,
      required: true
    },
    policy: {
      type: Object,
      required: true
    },
    session: {
      type: Object,
      required: true
    },
    errors: {
      type: Array,
      required: true
    }
  },

  methods: {

    onToggleEmoji() {
      this.$emit('on-toggle-emoji')
    },

    onSelectKeyword(kw) {
      this.$emit('on-select-keyword',kw)
    },

    onSelectHashtag(ht) {
      console.log('GrammarlyEditor.onSelectHashtag',ht)
      this.$emit('on-select-hashtag',ht)
    },

    attached(filesUploaded) {
      this.$emit('on-attached', filesUploaded)
    },

    findPosition(e) {
      this.selectionStart = e.target.selectionStart
      this.selectionEnd = e.target.selectionEnd
    },

    fetchPosition() {
      return this.selectionEnd
    },

    fetchEditor() {
      return this.$refs.post_editor_v2
    },

    fetchSelection() {
      return {
        start: this.selectionStart,
        end: this.selectionEnd
      }
    }

  },

  // set focus to editor in order for grammarly to function
  created() {
    setTimeout(()=>{
      this.$refs.post_editor_v2.focus()
    },100)

  },

  /**
  watch: {
    'post.preview_cache'() {
      console.log('post.preview_cache changed', this.post.preview_cache)
    },
  },
  **/

  components: {
    GrammarlyEditorPlugin,
    ContentStatus,
    Grammarly,
    Preview
  }
};
</script>

<style>

.grammarly-edtor-v2 {
  border: 2px solid #008CFF!important;
  border-radius: 8px;
}

.grammarly-edtor-v2 textarea {
  resize: none !important;
  border: 0px solid lightgrey!important;
  border-radius: 8px;
  padding: 14px;
  width: 100%;
  outline: 0;
}

</style>
