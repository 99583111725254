<template>
  <div class="onboarding-model d-flex align-items-center justify-content-center" :class="completed?'completed':''">
    <div class="container">
        <h3>{{title}}</h3>
        <p v-if="!completed">This should only take a minute...</p>        
        <div>

          <!-- autoplay until completed -->
          <video v-if="!completed" autoplay loop height="250px">
            <source src="~@/assets/onboarding/v2/lately_heart_ends.mp4" type="video/mp4"/>
            Your browser does not support HTML5 video.
          </video>

          <!-- terminate autoplay when completed-->
          <video v-if="completed" height="250px">
            <source src="~@/assets/onboarding/v2/lately_heart_ends.mp4" type="video/mp4"/>
            Your browser does not support HTML5 video.
          </video>          

        </div>
        <p class="randomisms" v-if="!completed && message"><i>{{message}}...</i></p>

        <!-- when we're ready.. -->
        <b-button v-if="completed" @click="next(1)">GREAT, SHOW ME HOW IT WORKS!</b-button>
        
    </div>
  </div>
</template>

<script>

// timeout for completion of import
const TIMEOUT = 30000;

import { actions, getters } from '@/services/store'

export default {
  name: 'Model',
  data() {
    return {
      random:[],
      messages: [],
      timeout: false,
      completed: false,
      start: undefined,
      message: undefined,
      title: 'Building your first Voice Model!'      
    }
  },
  async created() {
    this.messages = await actions.fetchRandomContent()
    this.dashboard = getters.dashboard()   
    this.start = Date.now() 
    this.poll()
  },
  destroyed() {
    this.start = null
    if ( this.timer ) {
      clearTimeout(this.timer)
    }
  },
  methods: {
    next(dir) {
      this.$router.push(`/${this.dashboard._id}/start`)
    },
    randomize() {
      this.random = this.messages.slice().sort(() => Math.random() - 0.5)
    },
    complete() {
      this.title = 'Congratulations, your first Voice Model is ready!'               
      this.completed = true
    },
    async poll() {

      // move things along if the import doesn't complete in a timely manner
      if ( ( Date.now() - this.start ) > TIMEOUT ) {
        return this.complete() 
      } else {

        if ( !this.random.length ) {
          this.randomize()
        }

        this.message = this.random.length? this.random.pop() + '...' : ''

        try {
          const dashboard = getters.dashboard()
          this.stats = dashboard? await actions.fetchOnboardStats(dashboard._id) : false
        } catch( err ) {
          console.error(err) // log but otherwise continue polling until timeout
        } finally {
          if ( !this.stats || (this.stats.status !== 'COMPLETED' && this.stats.status !== 'ABANDONED') ) {
            this.timeout = setTimeout(this.poll,3000)
          } else {
            this.complete()
          }
        }

      }
    }
  }
}
</script>

<style lang="scss" >
.onboarding-model {

  flex: 1;
  background-color: white;

  font-family: Noto Sans;

  min-height: 100vh;

  h3 {
    font-size: 32px;
    font-weight: bold;
  }

  button {
    margin-top: 50px;
  }

  p.randomisms {
    margin-top: 30px;
    color: grey;
  }
}

.onboarding-model.completed {
  background-image: url('~@/assets/onboarding/v2/bg6.png'); 
  background-size: 100% 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
}
</style>
