<template>

  <div class="container nopad signup-page v2-product-info">

    <ConfirmDialog id="non-stripe-coupon" :title="nonStripeCouponMsg" :click="completeCoupon" :open="nonStripeCoupon" :nocancel="true" />

    <b-overlay :show="loading">

      <template #overlay>
        <div class="text-center">
          <p><i class="fa fa-spinner fa-spin" /></p>
          <p id="cancel-label">Please do not refresh your browser...</p>
        </div>
      </template>      

      <!-- heading -->
      <b-row class="heading">
        <b-col sm="12">
          <h3 class="lead" v-if="subscription && subscription.status==='inactive'">Welcome back!</h3>          
          <h3 v-if="isIframe">Please select a Plan:</h3>
          <h3 v-else-if="subscription && subscription.status==='inactive'">Please select a Plan:</h3>
          <h3 v-else>Next, select your Plan:</h3>          
          <p>Switch plans or cancel anytime.</p>
        </b-col>
        <b-col sm="12 section-header">
          <h4>Plan Options</h4>
        </b-col>
      </b-row>

      <!-- billing cycles -->
      <b-row class="cycles">
        <b-col sm="4"  @click="cycle='month'">
          <div class="cycle" v-bind:class="{ selected:cycle==='month' }">
            <span> Bill monthly</span>
            <i class="float-right" :class="cycle==='month'? 'fas fa-check-circle checked':'far fa-check-circle'" />
            <p> </p>
          </div>
        </b-col>
        <b-col sm="4" @click="cycle='year'">
          <div class="cycle" v-bind:class="{ selected:cycle==='year' }">
            <span> Bill yearly </span>
            <i class="float-right" :class="cycle==='year'? 'fas fa-check-circle checked':'far fa-check-circle'" />
            <p> Save up to 30%! </p>
          </div>
        </b-col>
      </b-row>

      <!-- plans -->
      <b-row class="plans">

        <b-col sm="3" v-for="(plan,idx) in products" :key="idx">
          <PlanInfo class="plan" :plan="plan" :cycle="cycle" @plan-selected="onPlanSelected" :errors="errors" />
        </b-col>

      </b-row>

      <b-row class="compare">

        <p class="text-center">
          <b-link v-b-modal.plan-comparison variant="link"> To compare the full list of features, click here</b-link>
          <PlanComparison />
        </p>
        
      </b-row>

      <!-- Review -->
      <b-row class="review">

        <ul>
          <li>You've selected the {{cycle}}ly {{plan.name}} plan. </li>
          <li>Your recurring payments will be ${{prices[cycle+'ly']}} per {{cycle}}, renewing each {{cycle}}.</li>
          <li><b>The total amount due today is ${{prices[cycle+'ly']}}.</b></li>
        </ul>

      </b-row>

      <!-- payment -->
      <b-row class="payment">
        <b-col sm="8">
          <PaymentInfo ref="PaymentInfo" :user="user" @signup-v2-mark="mark" :errors="errors" :showErrors="showErrors" />
        </b-col>

      </b-row>

      <b-row class="agreement">

        <b-col sm="12 nopad">
          <p>You will be charged ${{prices[cycle+'ly']}}/{{cycle}} (plus applicable taxes) on a recurring basis. 
            Your subscription will continue until you cancel. You can cancel at any time via your Lately
            account, or by contacting us, but you will not receive a pro-rated refund. By selecting 'SUBMIT', you agree to the above terms.</p>
        </b-col>

      </b-row>

      <b-row class="eulac">
        <b-col sm="12 nopad">
          <b-form-checkbox
            id="checkbox-1"
            v-model="termsAccepted"
            name="checkbox-1"
            :value="true"
            :unchecked-value="false"
          > I agree to Lately's <a target="blank" href="https://lately.ai/terms">Terms & Conditions</a> and <a target="blank" href="https://lately.ai/privacy">Privacy Policy</a>.
          </b-form-checkbox>
          <small class="text-danger" v-if="showErrors && errors.includes('termsAccepted')">Please agree to the Terms & Conditions and Privacy Policy.</small>
        </b-col>
      </b-row>

      <b-row class="submit">

        <b-col sm="12">
          <b-button :disabled="showErrors && (errors.length>0)" @click="submit()" variant="success">SUBMIT</b-button>
          <p><small class="text-danger" v-if="showErrors && (errors.length>0)">Please correct {{errors.length}} highlighted errors above</small></p>
        </b-col>
      </b-row>

    </b-overlay>

  </div>

</template>

<script>

'use strict'

import ConfirmDialog from '@/components/ConfirmDialog'
import { Publishers } from '@/services/constants'
import { includes } from '@/services/product'
import PlanComparison from './PlanComparison'
import PaymentInfo from './PaymentInfo'
import PlanInfo from './PlanInfo'
import moment from 'moment'

import { getters, actions } from '@/services/store'

export default {

  name: 'ProductInfo',

  data() {
    return {
      plan: false,
      timer: false,
      cycle: 'month',
      products: [],
      subscription: false,
      nonStripeCoupon: false,
      nonStripeCouponMsg: '',
      prices: {
        monthly: 0,
        yearly: 0,
        discount: 0
      },
      loading: false,
      showErrors: false,
      termsAccepted: false,
      trialEnd: moment().add(7,'days').format('dddd, MMMM DD, YYYY'),
      isIframe: getters.isIframe('hootsuite'),
      errors: ['cardHolder.card','cardHolder.fullName','cardHolder.phone','termsAccepted'],
    }
  },

  props: {
    user: {
      type:Object,
      required:true
    }
  },

  async created() {

    if ( this.user.state === 'complete' ) {
      const route = await actions.init()
      this.$router.push(route)
    }

    this.subscription = getters.subscription() 
    let products = await actions.fetchProducts()

    // remove those that are not user selectable, ie invisible 
    products = products.filter((p)=>{ return p.userFacing }) 

    products.forEach((p)=>{ p.selected = false })

    // add an enterprisely plan
    products.push({
      active: true,
      id: 'enterprise',
      name: 'Enterprise',
      description: `(features)
      Unlimited Users
      Unlimited Social Accounts
      Advanced analytics and AI-powered insights
      AI-optimized scheduling and calendar
      AI-powered topic recommendations
      AI-powered employee advocacy and social selling tools
      Campaign comparison and performance tracking
      Advanced video generation
      VIP AI training and education services`,
      prices: [],
      limits: [],
      demo: 'https://www.lately.ai/demo'
    })

    // return active products filtered to user selected publisher
    this.products = products.filter((p)=>{
      return p.id === 'enterprise' || (p.active && p.prices.filter((pr)=>{return pr.active}).length == 2)
    }).sort((p1,p2)=>{
      const pr1 = p1.prices.find((pr)=>{return pr.interval === 'year' })
      const pr2 = p2.prices.find((pr)=>{return pr.interval === 'year' })
      return p1.amount - p2.amount
    })

    if ( this.products.length ) {
      this.onPlanSelected( this.products[0] )
    }

    // refresh scenario
    if ( this.user.state === 'pending' ) {
      this.loading = true
      this.poll()
    }

  },

  destroyed() {
    if ( this.timer ) {
      clearTimeout(this.timer)
      this.timer = undefined
    }
  },

  watch: {
    termsAccepted() {
      this.mark( 'termsAccepted', this.termsAccepted )
    }
  },

  methods: {

    async poll() {
      this.timer = undefined
      let user = await actions.fetchUser() 
      if ( user && user.state === 'complete' ) {
        const dashboard = getters.dashboard()
        this.$router.push(`/${dashboard._id}/onboarding`)
      } else {
        this.timer = setTimeout( this.poll, 500 ) 
      }
    },

    logoFor(publisher) {
      return require(`@/assets/integrations/${publisher.img}`)
    },

    mark( field, val ) {
      const idx = this.errors.indexOf(field)
      if ( idx === -1 && !val ) {
        const s = this.errors.push(field)
      } else if ( val && idx >= 0 ) {
        this.errors.splice(idx,1)
      }
      return val;
    },

    onPlanSelected(plan) {
      this.products.forEach((p) => {
        p.selected = (p === plan)
      })
      this.plan=plan
      this.computePrices()
    },

    computePrices() {
      const monthly = this.plan.prices.find((p)=>{return p.interval==='month'})
      const yearly = this.plan.prices.find((p)=>{return p.interval==='year'})
      if ( monthly && yearly ) {
        this.prices.monthly = monthly.amount
        this.prices.yearly = yearly.amount
        this.prices.discount = Math.ceil((1 - (this.prices.yearly/(this.prices.monthly*12)))*100)
        // format for display
        this.prices.monthly = Number(this.prices.monthly).toLocaleString()
        this.prices.yearly = Number(this.prices.yearly).toLocaleString()
      }
    },

    async completeCoupon() {
      this.nonStripeCoupon = false
    },

    async submit() {

      this.showErrors = (this.errors.length > 0)
      if ( this.errors.length ) return;

      try {

        this.loading = true;
        const product = this.plan
        const price = this.plan.prices.find((p)=>{return p.interval===this.cycle})
        const cardHolder = this.$refs.PaymentInfo.fetchCardholder()
        const cardToken = await this.$refs.PaymentInfo.fetchCardToken()
        const values = {
          token: cardToken.token.id,
          cardHolder: cardHolder,
          product: product.id,
          price: price.id,
        }

        // coerce hootsuite as publisher when embedded
        if ( getters.isIframe('hootsuite') ) {
          const user = getters.user()
          const hootsuite = Publishers.find((p)=>{ return p.id==='hootsuite' })
          hootsuite.mode = 'embedded'
          user.signup.publisher = hootsuite
          await actions.patchUser(user._id,'signup.publisher',user.signup.publisher)
        }

        // coerce Lately publisher for Startly
        else if ( !includes('connect-third-party', product ) ) {
          console.log('coercing lately publisher')
          const user = getters.user()
          const lately = Publishers.find((p)=>{ return p.id==='lately' })
          user.signup.publisher = lately
          await actions.patchUser(user._id,'signup.publisher',user.signup.publisher)
        } else {
          console.log
        }

        // validate a non-stripe coupon
        const coupon = cardHolder.coupon || ''
        if ( coupon.toLowerCase() === 'bftwofer' ) {
          if ( price.interval !== 'year' || (product.name !== 'Professionally' && product.name !== 'Litely') ) {
            this.nonStripeCouponMsg = `To apply coupon '${cardHolder.coupon}', please choose an annual subscription to either the Professionally or Litely plan.`
            return this.nonStripeCoupon = true
          }
        }

        // allow busy indicator to be seen
        window.scrollTo({ top: 500, behavior: 'smooth' });

        const response = await actions.completeTrialRegistration(this.user,values,product,price)
        this.$router.push(`/${response.dashboard._id}/onboarding`)

      } catch( err ) {
        console.error(err)
        this.$toasted.error(err.message)
      } finally {
        this.loading = false;
      }

    }
  },

  components: {
    PlanComparison,
    ConfirmDialog,
    PaymentInfo,
    PlanInfo
  }

}
</script>

<style lang="scss" >

.v2-product-info {

  margin-top: 50px;

  font-family: Noto Sans;

  .col-sm-4::nth-child(even) {
    padding-left:5px!important;
    padding-right:5px!important;
  }

  .heading {
    margin-top: 50px!important;
    h3 {
      font-size: 42px;
      font-weight: bold;
    }
    h4 {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    p {
      font-size: 20px;
      font-weight: normal;
      margin-bottom: 40px;
    }
  }

  .cycles {

    .cycle {
      font-size: 18px;
      font-weight: medium;
      background-color: white;
      border: 3px solid #E6E6E6;
      border-radius: 8px;
      min-height: 110px;
      padding:24px;
      span {
        font-weight:bold;
      }
      i {
        color: #E6E6E6;
        font-size:32px;
      }

      p {
        margin:0px;
      }
    }

    .cycle.selected {
      border: 3px solid #008CFF;
      i {
        color: #008CFF
      }
    }

  }

  .plans {

    .plan {

      margin-top:72px;

      .section-header {
        h4 {
          font-size: 28px;
          font-weight: bold;
        }
        margin-bottom: 0px;
      }

    }

    ::first {
      padding-left:0px!important;
    }

  }

  .compare {
    font-size: 22px;
    font-weight: bold;
    padding-top: 30px;

    p {
      margin: 0px;
      a {
        color: #008CFF;
      }        
    }    
  }

  .review {
    margin: 40px 0px 0px 0px;
    padding: 30px;
    font-size: 20px;
    background-color: #E1F2E8;
  }

  .payment {
    margin-top:40px;
  }

  .agreement {
    margin: 0px;
    font-size: 16px;
    font-weight: regular;
    ul {
      margin-bottom: 0px!important;
    }
    p {
      margin-bottom: 0px;
    }
  }

  .eulac {
    font-size: 16px;
    margin: 36px 0px 0px 0px;
    p {
      margin-top: 0px;
      margin-bottom: 36px;
    }
    input {
      font-size: 22px!important;
    }
  }

  .submit {
    margin-top: 36px;
    button {
      background-color: #20C763;
      border-radius: 8px;
      font-size: 20px;
      font-weight: bold;

      padding: 20px 60px 20px 60px;

    }
  }

  .publisher-icon {
    padding-left: 10px;
    padding-bottom: 10px;
  }

  .product-feature img {
    border-radius:10px;
  }

  .product-feature p {
    padding-top: 20px;
    font-weight: bold;
  }

  .row.padded {
    margin-top:10px;
  }

  .plan-transition {
    margin-left:25px;
  }

}

</style>

