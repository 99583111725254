<template>
  <div>
    <ChipsMultiselect v-model="selections" :items='options' />
  </div>
</template>

<script>

import ChipsMultiselect from 'v-chips-multiselect'
import { Categories } from '@/services/constants'

export default {

  name: 'Interests',

  data() {
    return {
      updates: 0,
      options: Categories.slice().map((c)=>c.title),
      selections: this.initial.slice()
    }
  },

  props: {
    initial: {
      type: Array
    }
  },

  methods: {
    fetchSelections() {
      return this.selections.slice()
    }
  },

  watch: {
    selections() {
      // workaround for widget not preserving initial model
      if ( !this.updates++ ) {
        console.log('pushing', this.initial)
        this.selections.push.apply( this.selections, this.initial )
      }
      console.log('selections changed to', this.updates,this.selections)
    }
  },

  components: {
    ChipsMultiselect
  }

}
</script>

<style lang="scss" >

h1.msg {
  color: $gray-900;
}
</style>
