const IMAGES = ['image/*']
const VIDEOS = ['video/*', '.mov', '.mp4']

const acceptable = {
    twitter: IMAGES.concat(VIDEOS),
    facebook: IMAGES.concat(VIDEOS),
    instagram: IMAGES.concat(VIDEOS),
    pinterest: IMAGES,
    linkedin: IMAGES.concat(VIDEOS),
    youtube: VIDEOS,
}

const maxImages = {
    twitter: 4,
    linkedin: 9,
    facebook: 8,
    instagram: 8,
    pinterest: 1,
    youtube: 0,
}

const maxAudios = {
    twitter: 0,
    linkedin: 0,
    facebook: 0,
    instagram: 0,
    pinterest: 0,
    youtube: 0,
}

const maxVideos = {
    twitter: 1,
    linkedin: 1,
    facebook: 1,
    instagram: 1,
    pinterest: 0,
    youtube: 1,
}

const minImages = {
    twitter: 0,
    linkedin: 0,
    facebook: 0,
    instagram: 0,
    pinterest: 0,
    youtube: 0,
}

const minVideos = {
    twitter: 0,
    linkedin: 0,
    facebook: 0,
    instagram: 0,
    pinterest: 0,
    youtube: 1,
}

const imageSizes = {
    twitter: 0,
    linkedin: 0,
    facebook: 0,
    instagram: 1,
    pinterest: 0,
    youtube: 0,
}

const videoSizes = {
    twitter: 0,
    linkedin: 0,
    facebook: 0,
    instagram: 1,
    pinterest: 0,
    youtube: 0,
}

const TEXT_SOURCES_FILTER_DEFS = {
    reject: ['facebook', 'google'],
    message: `Sorry, that doesn't look like a blog post! Please try linking to a publicly available post.`,
}

/**
 * Assumes url is valid!
 **/
export const text_sources_domain_filter = (url) => {
    const host = new URL(url).host
    const blocked = TEXT_SOURCES_FILTER_DEFS.reject.filter((s) => {
        return host === s || host.includes(s)
    })
    return blocked.length ? TEXT_SOURCES_FILTER_DEFS.message : ''
}

export const media = {
    facebook: {
        label: 'Facebook',
        maxLength: 2000,
        maxAttachments: 8,
        minAttachments: 0,
        minImages: minImages.facebook,
        maxImages: maxImages.facebook,
        minVideos: minVideos.facebook,
        maxVideos: maxVideos.facebook,
        maxAudios: maxAudios.facebook,
        mimetypes: acceptable.facebook,
        maxImageSize: imageSizes.facebook,
        maxVideoSize: videoSizes.facebook,
        icon: 'fab fa-facebook',
        color: '#3b5998',
    },
    twitter: {
        label: 'Twitter',
        maxLength: 280,
        maxAttachments: 4,
        minAttachments: 0,
        minImages: minImages.twitter,
        maxImages: maxImages.twitter,
        minVideos: minVideos.twitter,
        maxVideos: maxVideos.twitter,
        maxAudios: maxAudios.twitter,
        mimetypes: acceptable.twitter,
        maxImageSize: imageSizes.twitter,
        maxVideoSize: videoSizes.twitter,
        icon: 'fab fa-twitter',
        color: '#14171A',
    },
    instagram: {
        label: 'Instagram',
        maxLength: 2400,
        maxAttachments: 1,
        minAttachments: 1,
        minImages: minImages.instagram,
        maxImages: maxImages.instagram,
        minVideos: minVideos.instagram,
        maxVideos: maxVideos.instagram,
        maxAudios: maxAudios.instagram,
        mimetypes: acceptable.instagram,
        maxImageSize: imageSizes.instagram,
        maxVideoSize: videoSizes.instagram,
        icon: 'fab fa-instagram',
        color: '#C13584',
    },
    linkedin: {
        label: 'Linkedin',
        maxLength: 3000,
        maxAttachments: 9,
        minAttachments: 0,
        minImages: minImages.linkedin,
        maxImages: maxImages.linkedin,
        minVideos: minVideos.linkedin,
        maxVideos: maxVideos.linkedin,
        maxAudios: maxAudios.linkedin,
        mimetypes: acceptable.linkedin,
        maxImageSize: imageSizes.linkedin,
        maxVideoSize: videoSizes.linkedin,
        icon: 'fab fa-linkedin',
        color: '#0077b5',
    },
    youtube: {
        label: 'Youtube',
        maxLength: 2000,
        maxAttachments: 1,
        minAttachments: 1,
        minImages: minImages.youtube,
        maxImages: maxImages.youtube,
        minVideos: minVideos.youtube,
        maxVideos: maxVideos.youtube,
        maxAudios: maxAudios.youtube,
        mimetypes: acceptable.youtube,
        maxImageSize: imageSizes.youtube,
        maxVideoSize: videoSizes.youtube,
        icon: 'fab fa-youtube',
        color: '#cc181e',
    },
}

/**
 * Upcontent categories
 **/
export const Categories = [
    {
        title: 'Apparel and Fashion',
        url: 'https://galleries.upcontent.com/galleries/ab002cd8-c2a2-4248-95ba-7d735bb88377/collections/only/selections.json',
    },
    {
        title: 'Banking',
        url: 'https://galleries.upcontent.com/galleries/3ccc938f-6f30-4d22-92a8-76b93ebcee27/collections/only/selections.json',
    },
    {
        title: 'Biotech',
        url: 'https://galleries.upcontent.com/galleries/9b4c53d9-0013-429f-ab9b-2897c819ed55/collections/only/selections.json',
    },
    {
        title: 'Broadcast Media',
        url: 'https://galleries.upcontent.com/galleries/68a82c7a-07b5-48bb-955b-1a33b8b5d3ef/collections/only/selections.json',
    },
    {
        title: 'Consumer Services',
        url: 'https://galleries.upcontent.com/galleries/009a2c76-fbbd-4f8c-b059-93de7ea8f0bb/collections/only/selections.json',
    },
    {
        title: 'Financial Services',
        url: 'https://galleries.upcontent.com/galleries/6428528a-7c49-454b-9e9a-648522deedac/collections/only/selections.json',
    },
    {
        title: 'Health, Wellness, and Fitness',
        url: 'https://galleries.upcontent.com/galleries/58e870bc-c0a7-41d7-a5b0-4897a8330a1b/collections/only/selections.json',
    },
    {
        title: 'eLearning',
        url: 'https://galleries.upcontent.com/galleries/4143900e-b883-45f0-810b-b287577c6c20/collections/only/selections.json',
    },
    {
        title: 'Accounting',
        url: 'https://galleries.upcontent.com/galleries/6411a871-94b4-4b08-bfbe-0585aedad000/collections/only/selections.json',
    },
    {
        title: 'Computer Games',
        url: 'https://galleries.upcontent.com/galleries/ed820400-5fd9-4eb7-a0ca-e0b08c8d701f/collections/only/selections.json',
    },
    {
        title: 'Computer Software',
        url: 'https://galleries.upcontent.com/galleries/f1762908-d7cd-45b8-a9b4-21c4b7440669/collections/only/selections.json',
    },
    {
        title: 'Entertainment',
        url: 'https://galleries.upcontent.com/galleries/e59f141d-3040-4bf2-984a-3d5efcd94e01/collections/only/selections.json',
    },
    {
        title: 'Information Technology ',
        url: 'https://galleries.upcontent.com/galleries/93e75d53-a301-4205-bc1d-bb987414bcf0/collections/only/selections.json',
    },
    {
        title: 'Insurance',
        url: 'https://galleries.upcontent.com/galleries/770e540d-226d-4d16-a9a5-d2864c203960/collections/only/selections.json',
    },
    {
        title: 'Law Practice',
        url: 'https://galleries.upcontent.com/galleries/59f6c8cd-f156-48cc-add8-a05ffbe12a63/collections/only/selections.json',
    },
    {
        title: 'Professional Training and Coaching',
        url: 'https://galleries.upcontent.com/galleries/2d28aa54-0139-47e4-b190-d3eb348cba9a/collections/only/selections.json',
    },
    {
        title: 'Real Estate',
        url: 'https://galleries.upcontent.com/galleries/2a079e09-41bc-4fda-94fb-72ddab48c4d4/collections/only/selections.json',
    },
    {
        title: 'Venture Capital / Private Equity',
        url: 'https://galleries.upcontent.com/galleries/f82b6b39-681d-4f26-a7f8-27662a1cf9d5/collections/only/selections.json',
    },
    {
        title: 'Marketing and Advertising',
        url: 'https://galleries.upcontent.com/galleries/ed2fffce-e895-4ae0-abf9-79be18e9680f/collections/only/selections.json',
    },
]

export const Publishers = [
    {
        id: 'lately',
        img: 'lately_logo.jpg',
        title: `Publish with Lately`,
        enabled: true,
        connectLabel: 'SELECT',
        summary: 'Publish using',
        description: `Schedule and publish Lately's AI-generated posts right from here. Easy peasy.`,
    },
    {
        id: 'hootsuite',
        img: 'hootsuite.png',
        title: 'Publish with Hootsuite',
        enabled: true,
        connectLabel: 'SELECT',
        summary: 'Publish using',
        description: `Send Lately's AI-generated posts to your Hootsuite account for scheduling and publishing.`,
    },
    {
        id: 'hubspot',
        img: 'hubspot.png',
        title: 'Publish with HubSpot Marketing Hub',
        enabled: true,
        connectLabel: 'SELECT',
        summary: 'Publish using',
        description: `Send Lately's AI-generated posts to your HubSpot Marketing Hub account for scheduling and publishing.`,
    },
    {
        id: 'sprinklr',
        img: 'Sprinklr-Logo.jpg',
        title: 'Publish with Sprinklr',
        enabled: true,
        connectLabel: 'SELECT',
        summary: 'Publish using',
        description: `Send Lately's AI-generated posts to your Sprinklr account for scheduling and publishing.`,
    },
]

const SOURCES = ['https://www.lately.ai/blog/how-to-use-artificial-intelligence-to-skyrocket-the-effectiveness-of-your-social-media-calendar-ann-smarty-of-viral-content-bee-interviews-kately-from-lately-ai', 'https://www.lately.ai/blog/can-ai-replace-human-writers', 'https://www.lately.ai/blog/modernize-the-way-you-market-hubspot-inbound-magic', 'https://www.lately.ai/blog/supercharge-your-content-with-lately-hubspot', 'https://www.lately.ai/blog/a-i-makes-social-selling-a-breeze', 'https://www.lately.ai/blog/turn-customers-into-fans-the-keys-to-customer-success', 'https://www.lately.ai/blog/how-to-write-like-the-boss-katelys-writing-rules', 'https://www.lately.ai/blog/how-a-i-is-flipping-the-marketing-world-upside-down', 'https://www.lately.ai/blog/marketing-yourself-before-and-after-you-get-the-job-lately-live-with-madeline-mann', 'https://www.lately.ai/blog/the-20-best-twitter-chats-for-marketers']

export const randomSource = () => {
    const min = 0,
        max = SOURCES.length - 1
    const idx = Math.round(Math.random() * (max - min) + min)
    return SOURCES[idx]
}

export const SESSION_KEYS = {
    SUCCESS_INDICATOR_SHOW_CHECKLIST: 'success-indicator-show-checklist',
}

const PROGRESS_MESSAGES = {
    PRE: [
        {
            id: 'accepted',
            checked: true,
            genre: 'PRE',
            txt: 'Analyzing social media data for insights',
        },
    ],

    URL: [
        {
            id: 'watson',
            checked: false,
            genre: 'URL',
            txt: 'Verifying link viability',
        },
    ],

    VIDEO: [
        {
            id: 'accepted',
            checked: false,
            genre: 'VIDEO',
            txt: 'Uploading video',
        },
        {
            id: 'ingested',
            checked: false,
            genre: 'VIDEO',
            txt: 'Normalizing video',
        },
        {
            id: 'transcribed',
            checked: false,
            genre: 'VIDEO',
            txt: 'Transcribing video',
        },
    ],

    POST: [
        {
            id: 'meaningcloud',
            checked: false,
            genre: 'POST',
            txt: 'Assessing effective messaging for engagement',
        },
        {
            id: 'keywords',
            checked: false,
            genre: 'POST',
            txt: 'Refining brand tone and style',
        },
        {
            id: 'watson',
            checked: false,
            genre: 'POST',
            txt: 'Identifying high-performing language',
        },
        {
            id: 'keywords',
            checked: false,
            genre: 'POST',
            txt: 'Locating content sections with these elements',
        },
        {
            id: 'ranked',
            checked: false,
            genre: 'POST',
            txt: 'Turning them into resonant social media posts',
        },
    ],
}

export const progressMessages = (type) => {
    let steps

    if (type === 'text') {
        steps = PROGRESS_MESSAGES.PRE.concat(PROGRESS_MESSAGES.POST)
    } else if (type === 'url') {
        steps = PROGRESS_MESSAGES.PRE.concat(PROGRESS_MESSAGES.URL).concat(PROGRESS_MESSAGES.POST)
    } else if (type === 'video') {
        steps = PROGRESS_MESSAGES.PRE.concat(PROGRESS_MESSAGES.VIDEO).concat(PROGRESS_MESSAGES.POST)
    }

    // crude deep clone
    steps = JSON.parse(JSON.stringify(steps))

    return steps
}

export const ANALYTICS = {
    mapOfStats: {
        totalPosts: 4,
        byDayOfWeek: {
            0: {
                key: 'Sunday',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            1: {
                key: 'Monday',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            2: {
                key: 'Tuesday',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            3: {
                key: 'Wednesday',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            4: {
                engagements: 0,
                impressions: 12,
                campaigns: [],
                posts: [
                    {
                        _id: '667e6da559c6ccd8aaa5743d',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667e6da559c6ccd8aaa57443',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667ecd10cdf5d220bd4a7e4e',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                ],
                impact: 12,
                key: 'Thursday',
            },
            5: {
                key: 'Friday',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            6: {
                key: 'Saturday',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
        },
        byHourOfDay: {
            0: {
                key: '12 am',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            1: {
                key: '1 am',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            2: {
                key: '2 am',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            3: {
                key: '3 am',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            4: {
                key: '4 am',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            5: {
                key: '5 am',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            6: {
                key: '6 am',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            7: {
                key: '7 am',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            8: {
                key: '8 am',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            9: {
                key: '9 am',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            10: {
                key: '10 am',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            11: {
                key: '11 am',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            12: {
                key: '12 pm',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            13: {
                key: '1 pm',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            14: {
                key: '2 pm',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            15: {
                key: '3 pm',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            16: {
                engagements: 0,
                impressions: 12,
                campaigns: [],
                posts: [
                    {
                        _id: '667e6da559c6ccd8aaa5743d',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667e6da559c6ccd8aaa57443',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667ecd10cdf5d220bd4a7e4e',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                ],
                impact: 12,
                key: '4 pm',
            },
            17: {
                key: '5 pm',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            18: {
                key: '6 pm',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            19: {
                key: '7 pm',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            20: {
                key: '8 pm',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            21: {
                key: '9 pm',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
            22: {
                key: '10 pm',
                engagements: 0,
                impressions: 0,
                campaigns: [],
                posts: [],
                impact: 0,
            },
        },
        byChannelType: {
            twitter: {
                engagements: 0,
                impressions: 9,
                campaigns: [],
                posts: [
                    {
                        _id: '667e6da559c6ccd8aaa5743d',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667e6da559c6ccd8aaa57443',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                ],
                impact: 9,
                key: 'twitter',
                type: 'twitter',
            },
            linkedin: {
                engagements: 0,
                impressions: 3,
                campaigns: [],
                posts: [
                    {
                        _id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667ecd10cdf5d220bd4a7e4e',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                ],
                impact: 3,
                key: 'linkedin',
                type: 'linkedin',
            },
        },
        byCampaign: {
            '59caeeb2a0da3e1ecf49bac7': {
                engagements: 0,
                impressions: 12,
                campaigns: [],
                posts: [
                    {
                        _id: '667e6da559c6ccd8aaa5743d',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667e6da559c6ccd8aaa57443',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667ecd10cdf5d220bd4a7e4e',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                ],
                impact: 12,
                key: '59caeeb2a0da3e1ecf49bac7',
                dashboard: '59b8bc3e618ffc164908bbfb',
                name: 'Content Imported By Lately',
                color: '#999',
                shortName: 'IMPORTED',
            },
        },
        byDashboard: {
            '59b8bc3e618ffc164908bbfb': {
                engagements: 0,
                impressions: 12,
                campaigns: [],
                posts: [
                    {
                        _id: '667e6da559c6ccd8aaa5743d',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667e6da559c6ccd8aaa57443',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667ecd10cdf5d220bd4a7e4e',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                ],
                impact: 12,
                key: '59b8bc3e618ffc164908bbfb',
                _id: '59b8bc3e618ffc164908bbfb',
                name: 'Welcome to IT Actual  Dashboard',
            },
        },
        byChannel: {
            11050203: {
                engagements: 0,
                impressions: 3,
                campaigns: [],
                posts: [
                    {
                        _id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667ecd10cdf5d220bd4a7e4e',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                ],
                impact: 3,
                key: '11050203',
                name: 'KBSJ Partners',
                type: 'linkedin',
                id: '11050203',
            },
            '799265536177819648': {
                engagements: 0,
                impressions: 9,
                campaigns: [],
                posts: [
                    {
                        _id: '667e6da559c6ccd8aaa5743d',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667e6da559c6ccd8aaa57443',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                ],
                impact: 9,
                key: '799265536177819648',
                name: 'KBSJ Partners',
                type: 'twitter',
                id: '799265536177819648',
            },
        },
        byAuthor: {
            '572ca58d8178509b0e04a62a': {
                engagements: 0,
                impressions: 12,
                campaigns: [],
                posts: [
                    {
                        _id: '667e6da559c6ccd8aaa5743d',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667e6da559c6ccd8aaa57443',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667ecd10cdf5d220bd4a7e4e',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                ],
                impact: 12,
                key: '572ca58d8178509b0e04a62a',
            },
        },
        byPostCharacteristics: {
            'Text+Link': {
                engagements: 0,
                impressions: 12,
                campaigns: [],
                posts: [
                    {
                        _id: '667e6da559c6ccd8aaa5743d',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667e6da559c6ccd8aaa57443',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667ecd10cdf5d220bd4a7e4e',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                ],
                impact: 12,
                key: 'Text+Link',
            },
        },
        byHashtag: {
            '#MotivationMatters': {
                engagements: 0,
                impressions: 2,
                campaigns: [],
                posts: [
                    {
                        _id: '667e6da559c6ccd8aaa57443',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                ],
                impact: 2,
                key: '#MotivationMatters',
                $chart: {
                    id: 3,
                    word: '#MotivationMatters',
                    posts: [
                        {
                            _id: '667e6da559c6ccd8aaa57443',
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                            author: '572ca58d8178509b0e04a62a',
                        },
                    ],
                    href: 'http://twitter.com/#MotivationMatters',
                    impact: {
                        type: 'Engagements',
                        value: 0,
                    },
                    campaigns: [],
                    size: 0,
                    class: 'new',
                    hide: true,
                    $$hashKey: 'object:1595',
                },
            },
            '#NoomJourney': {
                engagements: 0,
                impressions: 2,
                campaigns: [],
                posts: [
                    {
                        _id: '667e6da559c6ccd8aaa57443',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                ],
                impact: 2,
                key: '#NoomJourney',
                $chart: {
                    id: 4,
                    word: '#NoomJourney',
                    posts: [
                        {
                            _id: '667e6da559c6ccd8aaa57443',
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                            author: '572ca58d8178509b0e04a62a',
                        },
                    ],
                    href: 'http://twitter.com/#NoomJourney',
                    impact: {
                        type: 'Engagements',
                        value: 0,
                    },
                    campaigns: [],
                    size: 0,
                    class: 'new',
                    hide: true,
                    $$hashKey: 'object:1596',
                },
            },
            '#Longevity': {
                engagements: 0,
                impressions: 7,
                campaigns: [],
                posts: [
                    {
                        _id: '667e6da559c6ccd8aaa5743d',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                ],
                impact: 7,
                key: '#Longevity',
                $chart: {
                    id: 0,
                    word: '#Longevity',
                    posts: [
                        {
                            _id: '667e6da559c6ccd8aaa5743d',
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                            author: '572ca58d8178509b0e04a62a',
                        },
                    ],
                    href: 'http://twitter.com/#Longevity',
                    impact: {
                        type: 'Engagements',
                        value: 0,
                    },
                    campaigns: [],
                    size: 0,
                    class: 'new',
                    hide: true,
                    $$hashKey: 'object:1592',
                },
            },
            '#HealthyLiving': {
                engagements: 0,
                impressions: 7,
                campaigns: [],
                posts: [
                    {
                        _id: '667e6da559c6ccd8aaa5743d',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                ],
                impact: 7,
                key: '#HealthyLiving',
                $chart: {
                    id: 1,
                    word: '#HealthyLiving',
                    posts: [
                        {
                            _id: '667e6da559c6ccd8aaa5743d',
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                            author: '572ca58d8178509b0e04a62a',
                        },
                    ],
                    href: 'http://twitter.com/#HealthyLiving',
                    impact: {
                        type: 'Engagements',
                        value: 0,
                    },
                    campaigns: [],
                    size: 0,
                    class: 'new',
                    hide: true,
                    $$hashKey: 'object:1593',
                },
            },
            '#100Club': {
                engagements: 0,
                impressions: 7,
                campaigns: [],
                posts: [
                    {
                        _id: '667e6da559c6ccd8aaa5743d',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                ],
                impact: 7,
                key: '#100Club',
                $chart: {
                    id: 2,
                    word: '#100Club',
                    posts: [
                        {
                            _id: '667e6da559c6ccd8aaa5743d',
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                            author: '572ca58d8178509b0e04a62a',
                        },
                    ],
                    href: 'http://twitter.com/#100Club',
                    impact: {
                        type: 'Engagements',
                        value: 0,
                    },
                    campaigns: [],
                    size: 0,
                    class: 'new',
                    hide: true,
                    $$hashKey: 'object:1594',
                },
            },
        },
        byMention: {
            '@kbsjpartners': {
                engagements: 0,
                impressions: 2,
                campaigns: [],
                posts: [
                    {
                        _id: '667e6da559c6ccd8aaa57443',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                ],
                impact: 2,
                key: '@kbsjpartners',
                $chart: {
                    id: 1,
                    word: '@kbsjpartners',
                    posts: [
                        {
                            _id: '667e6da559c6ccd8aaa57443',
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                            author: '572ca58d8178509b0e04a62a',
                        },
                    ],
                    href: 'http://twitter.com/@kbsjpartners',
                    impact: {
                        type: 'Engagements',
                        value: 0,
                    },
                    campaigns: [],
                    size: 0,
                    class: 'none',
                    hide: true,
                    $$hashKey: 'object:1634',
                },
            },
            '@KBSJ': {
                engagements: 0,
                impressions: 5,
                campaigns: [],
                posts: [
                    {
                        _id: '667e6da559c6ccd8aaa5743d',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                    {
                        _id: '667ecd10cdf5d220bd4a7e4e',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                ],
                impact: 5,
                key: '@KBSJ',
                $chart: {
                    id: 0,
                    word: '@KBSJ',
                    posts: [
                        {
                            _id: '667e6da559c6ccd8aaa5743d',
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                            author: '572ca58d8178509b0e04a62a',
                        },
                        {
                            _id: '667ecd10cdf5d220bd4a7e4e',
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                            author: '572ca58d8178509b0e04a62a',
                        },
                    ],
                    href: 'http://twitter.com/@KBSJ',
                    impact: {
                        type: 'Engagements',
                        value: 0,
                    },
                    campaigns: [],
                    size: 0,
                    class: 'none',
                    hide: true,
                    $$hashKey: 'object:1633',
                },
            },
        },
        byKeyword: {
            transformation: {
                engagements: 0,
                impressions: 3,
                campaigns: [],
                posts: [
                    {
                        _id: '667ecd10cdf5d220bd4a7e4e',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        author: '572ca58d8178509b0e04a62a',
                    },
                ],
                impact: 3,
                key: 'transformation',
                $chart: {
                    id: 0,
                    word: 'transformation',
                    posts: [
                        {
                            _id: '667ecd10cdf5d220bd4a7e4e',
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                            author: '572ca58d8178509b0e04a62a',
                        },
                    ],
                    impact: {
                        type: 'Impressions',
                        value: 3,
                    },
                    campaigns: [],
                    size: 9,
                    class: 'new',
                    hide: false,
                },
            },
        },
        byHighlightKeyword: {
            increase: {
                engagements: 0,
                impressions: 7,
                campaigns: [],
                total: 7,
                posts: [
                    {
                        id: '667e6da559c6ccd8aaa5743d',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                    },
                    {
                        id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                    },
                ],
                key: 'increase',
                $chart: {
                    id: 0,
                    word: 'increase',
                    posts: [
                        {
                            id: '667e6da559c6ccd8aaa5743d',
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        },
                        {
                            id: '667ecd10cdf5d220bd4a7e4f',
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        },
                    ],
                    impact: {
                        type: 'Impressions',
                        value: 7,
                    },
                    campaigns: [],
                    size: 9,
                    class: 'new',
                    hide: false,
                },
            },
            chances: {
                engagements: 0,
                impressions: 7,
                campaigns: [],
                total: 7,
                posts: [
                    {
                        id: '667e6da559c6ccd8aaa5743d',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                    },
                    {
                        id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                    },
                ],
                key: 'chances',
                $chart: {
                    id: 1,
                    word: 'chances',
                    posts: [
                        {
                            id: '667e6da559c6ccd8aaa5743d',
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        },
                        {
                            id: '667ecd10cdf5d220bd4a7e4f',
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        },
                    ],
                    impact: {
                        type: 'Impressions',
                        value: 7,
                    },
                    campaigns: [],
                    size: 9,
                    class: 'new',
                    hide: false,
                },
            },
            healthy: {
                engagements: 0,
                impressions: 7,
                campaigns: [],
                total: 7,
                posts: [
                    {
                        id: '667e6da559c6ccd8aaa5743d',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                    },
                    {
                        id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                    },
                ],
                key: 'healthy',
                $chart: {
                    id: 2,
                    word: 'healthy',
                    posts: [
                        {
                            id: '667e6da559c6ccd8aaa5743d',
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        },
                        {
                            id: '667ecd10cdf5d220bd4a7e4f',
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        },
                    ],
                    impact: {
                        type: 'Impressions',
                        value: 7,
                    },
                    campaigns: [],
                    size: 9,
                    class: 'new',
                    hide: false,
                },
            },
            lifestyle: {
                engagements: 0,
                impressions: 0,
                campaigns: [],
                total: 0,
                posts: [
                    {
                        id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                    },
                ],
                key: 'lifestyle',
            },
            KBSJ: {
                engagements: 0,
                impressions: 0,
                campaigns: [],
                total: 0,
                posts: [
                    {
                        id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                    },
                ],
                key: 'KBSJ',
            },
            Partners: {
                engagements: 0,
                impressions: 0,
                campaigns: [],
                total: 0,
                posts: [
                    {
                        id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                    },
                ],
                key: 'Partners',
            },
            Longevity: {
                engagements: 0,
                impressions: 0,
                campaigns: [],
                total: 0,
                posts: [
                    {
                        id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                    },
                ],
                key: 'Longevity',
            },
            HealthyLiving: {
                engagements: 0,
                impressions: 0,
                campaigns: [],
                total: 0,
                posts: [
                    {
                        id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                    },
                ],
                key: 'HealthyLiving',
            },
            living: {
                engagements: 0,
                impressions: 0,
                campaigns: [],
                total: 0,
                posts: [
                    {
                        id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                    },
                ],
                key: 'living',
            },
            urn: {
                engagements: 0,
                impressions: 0,
                campaigns: [],
                total: 0,
                posts: [
                    {
                        id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                    },
                ],
                key: 'urn',
            },
            organization: {
                engagements: 0,
                impressions: 0,
                campaigns: [],
                total: 0,
                posts: [
                    {
                        id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                    },
                ],
                key: 'organization',
            },
            lnkd: {
                engagements: 0,
                impressions: 0,
                campaigns: [],
                total: 0,
                posts: [
                    {
                        id: '667ecd10cdf5d220bd4a7e4f',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                    },
                ],
                key: 'lnkd',
            },
            transformation: {
                engagements: 0,
                impressions: 3,
                campaigns: [],
                total: 3,
                posts: [
                    {
                        id: '667ecd10cdf5d220bd4a7e4e',
                        dashboard: '59b8bc3e618ffc164908bbfb',
                        campaign: ['59caeeb2a0da3e1ecf49bac7'],
                    },
                ],
                key: 'transformation',
                $chart: {
                    id: 3,
                    word: 'transformation',
                    posts: [
                        {
                            id: '667ecd10cdf5d220bd4a7e4e',
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                        },
                    ],
                    impact: {
                        type: 'Impressions',
                        value: 3,
                    },
                    campaigns: [],
                    size: 4,
                    class: 'new',
                    hide: false,
                },
            },
        },
    },
    mapOfAuthors: {
        '572ca58d8178509b0e04a62a': {
            id: '572ca58d8178509b0e04a62a',
            sessions: 0,
            impressions: 9,
            total: 9,
            email: 'greg@itactual.com',
            avatar: 'https://cdn.filestackcontent.com/3wEv2hv7SJqDfavthGEc',
            fullname: 'Gregory Hamel',
            username: 'greghamel',
            gravatar_valid: false,
        },
    },
    arrayOfAnalytics: [
        {
            dashboard: {
                _id: '59b8bc3e618ffc164908bbfb',
                name: 'Welcome to IT Actual  Dashboard',
                partner: false,
            },
            data: [
                [
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [9, 0, 9, 18, 2],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                    [0, 0, 0, null, 1],
                ],
                [
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        3,
                        0,
                        3,
                        {
                            followers: 7,
                            metric: 'Connections',
                        },
                        2,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                    [
                        0,
                        0,
                        0,
                        {
                            followers: null,
                            metric: 'Unsupported Series undefined',
                        },
                        1,
                    ],
                ],
                [
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                    [0, 0, 0, 0, 1],
                ],
            ],
            colors: ['#4099FF', '#0D903B', '#FF0000'],
            errors: [
                {
                    src: 'Google',
                    msg: 'Error: invalid_grant- please try again later.',
                },
                {
                    src: 'Google',
                    msg: "Unable to fetch analytics for Google property 'Lately AI Enterprise - GA4' - please try again later.",
                },
                {
                    src: 'Google',
                    msg: "Unable to fetch analytics for Google property 'Lately AI Add-On Product' - please try again later.",
                },
            ],
            series: [' @KBSJ Partners Twitter', ' KBSJ Partners LinkedIn', ' Greg Hamel Youtube'],
            summaries: [
                {
                    type: 'twitter',
                    total_posts: 2,
                    favorite_count: 0,
                    retweet_count: 0,
                    like_count: 0,
                    impressions: 9,
                    sessions: 0,
                    views: 9,
                    highlights: [
                        {
                            _id: '667e6da559c6ccd8aaa57443',
                            rank: {
                                score: 0,
                                basis: [],
                                successIndicator: 0,
                            },
                            preview_cache: {
                                images: [],
                                url: 'https://t.co/dCtSv692Tq',
                                title: 'https://www.everydayhealth.com/diet-nutrition/diet/noom-guide-weight-watchers-millennials/?dash=itactual',
                                description: 'https://t.co/dCtSv692Tq',
                                image_url: '',
                                edited: false,
                                editable: false,
                            },
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                            contentSubType: 'post',
                            visibility: 'public',
                            source: 'Import',
                            contentSource: 'Manual',
                            genSource: 'Manual',
                            hasGenVariant: false,
                            genType: 'Manual',
                            approvalStatus: 'U',
                            edited: false,
                            status: 'P',
                            importStatus: 'complete',
                            influencerStatus: 'pending',
                            attempts: 1,
                            err: 'false',
                            publish_err: '',
                            validation_err: '',
                            tags: [],
                            createdBy: '572ca58d8178509b0e04a62a',
                            content: "Just because it's on your phone doesn't mean it works! Personal commitment is key to making Noom effective. 💪 #MotivationMatters #NoomJourney  @kbsjpartners 📱 ",
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            createdAt: '2024-06-27T22:57:35.000Z',
                            updatedAt: '2024-06-28T08:00:37.240Z',
                            publishAt: '2024-06-27T22:57:35.000Z',
                            contentType: 'twitter',
                            attachments: [],
                            channel: '799265536177819648',
                            url: 'https://twitter.com/799265536177819648/status/1806461907429478726',
                            post_meta: {
                                urls: ['https://t.co/dCtSv692Tq'],
                                tags: ['MotivationMatters', 'NoomJourney'],
                                mentions: ['kbsjpartners'],
                                retweets: [],
                                id: '1806461907429478726',
                                retweet_count: 0,
                                reply_count: 0,
                                like_count: 0,
                                quote_count: 0,
                                favorite_count: 0,
                                impressions: 2,
                                sessions: 0,
                                reach: 18,
                            },
                            author_meta: {
                                listed_count: 0,
                                friends_count: 0,
                                statuses_count: 0,
                                favourites_count: 0,
                                followers_count: 0,
                                url: 'https://twitter.com/undefined',
                                name: 'KBSJ Partners',
                            },
                            channel_meta: {
                                id: '6509c60c3acf0a0d01beb005',
                                name: 'KBSJ Partners',
                                logo: 'https://pbs.twimg.com/profile_images/965954256984518656/GNfriYj3_normal.jpg',
                                handle: 'kbsjpartners',
                            },
                            publish_errors: [],
                            originalContent: "Just because it's on your phone doesn't mean it works! Personal commitment is key to making Noom effective. 💪 #MotivationMatters #NoomJourney  @kbsjpartners 📱 https://t.co/dCtSv692Tq",
                            __v: 0,
                            analytic_content: {
                                frags: ['Just', 'because', "it's", 'on', 'your', 'phone', "doesn't", 'mean', 'it', 'works!', 'Personal', 'commitment', 'is', 'key', 'to', 'making', 'Noom', 'effective.', '💪', '', '📱'],
                            },
                            urls: [
                                {
                                    type: 'url',
                                    value: 'https://t.co/dCtSv692Tq',
                                    isLink: true,
                                    href: 'https://t.co/dCtSv692Tq',
                                    start: 161,
                                    end: 184,
                                    $$hashKey: 'object:1806',
                                },
                            ],
                            preview: {
                                url: 'https://t.co/dCtSv692Tq',
                                contentType: 'text/html',
                                title: '',
                                description: '',
                                images: [],
                                meta: {
                                    valid: false,
                                },
                            },
                            analytics_meta: false,
                            $$hashKey: 'object:1697',
                        },
                        {
                            _id: '667e6da559c6ccd8aaa5743d',
                            rank: {
                                score: 0,
                                basis: [],
                                successIndicator: 0,
                            },
                            preview_cache: {
                                images: [],
                                url: 'https://t.co/FxhH9srxQT',
                                title: 'https://www.everydayhealth.com/healthy-aging/what-habits-can-help-you-live-to-be-one-hundred-years-old/?dash=itactual',
                                description: 'https://t.co/FxhH9srxQT',
                                image_url: '',
                                edited: false,
                                editable: false,
                            },
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                            contentSubType: 'post',
                            visibility: 'public',
                            source: 'Import',
                            contentSource: 'Manual',
                            genSource: 'Manual',
                            hasGenVariant: false,
                            genType: 'Manual',
                            approvalStatus: 'U',
                            edited: false,
                            status: 'P',
                            importStatus: 'complete',
                            influencerStatus: 'pending',
                            attempts: 1,
                            err: 'false',
                            publish_err: '',
                            validation_err: '',
                            tags: [],
                            createdBy: '572ca58d8178509b0e04a62a',
                            content: '🌱🏃‍♂️ Want to increase your chances of reaching 100 years old? Longo suggests a healthy lifestyle, including regular exercise and a mostly vegan diet.  @KBSJ%20Partners #Longevity #HealthyLiving #100Club 🌿🍎 ',
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            createdAt: '2024-06-27T22:44:34.000Z',
                            updatedAt: '2024-06-28T08:00:37.030Z',
                            publishAt: '2024-06-27T22:44:34.000Z',
                            contentType: 'twitter',
                            attachments: [],
                            channel: '799265536177819648',
                            url: 'https://twitter.com/799265536177819648/status/1806458629681471660',
                            post_meta: {
                                urls: ['https://t.co/FxhH9srxQT'],
                                tags: ['Longevity', 'HealthyLiving', '100Club'],
                                mentions: ['KBSJ'],
                                retweets: [],
                                id: '1806458629681471660',
                                retweet_count: 0,
                                reply_count: 0,
                                like_count: 0,
                                quote_count: 0,
                                favorite_count: 0,
                                impressions: 7,
                                sessions: 0,
                                reach: 18,
                            },
                            author_meta: {
                                listed_count: 0,
                                friends_count: 0,
                                statuses_count: 0,
                                favourites_count: 0,
                                followers_count: 0,
                                url: 'https://twitter.com/undefined',
                                name: 'KBSJ Partners',
                            },
                            channel_meta: {
                                id: '6509c60c3acf0a0d01beb005',
                                name: 'KBSJ Partners',
                                logo: 'https://pbs.twimg.com/profile_images/965954256984518656/GNfriYj3_normal.jpg',
                                handle: 'kbsjpartners',
                            },
                            publish_errors: [],
                            originalContent: '🌱🏃‍♂️ Want to increase your chances of reaching 100 years old? Longo suggests a healthy lifestyle, including regular exercise and a mostly vegan diet.  @KBSJ%20Partners #Longevity #HealthyLiving #100Club 🌿🍎 https://t.co/FxhH9srxQT',
                            __v: 0,
                            analytic_content: {
                                frags: ['🌱🏃‍♂️', 'Want', 'to', 'increase', 'your', 'chances', 'of', 'reaching', '100', 'years', 'old?', 'Longo', 'suggests', 'a', 'healthy', 'lifestyle,', 'including', 'regular', 'exercise', 'and', 'a', 'mostly', 'vegan', 'diet.', '', '🌿🍎'],
                            },
                            urls: [
                                {
                                    type: 'url',
                                    value: 'https://t.co/FxhH9srxQT',
                                    isLink: true,
                                    href: 'https://t.co/FxhH9srxQT',
                                    start: 211,
                                    end: 234,
                                    $$hashKey: 'object:1830',
                                },
                            ],
                            preview: {
                                url: 'https://t.co/FxhH9srxQT',
                                contentType: 'text/html',
                                title: '',
                                description: '',
                                images: [],
                                meta: {
                                    valid: false,
                                },
                            },
                            analytics_meta: false,
                            $$hashKey: 'object:1698',
                        },
                    ],
                    id: '799265536177819648',
                    name: 'kbsjpartners',
                    followers: 0,
                    $$hashKey: 'object:1679',
                },
                {
                    type: 'linkedin',
                    total_posts: 2,
                    reach: 14,
                    like_count: 0,
                    impressions: 3,
                    share_count: 0,
                    comment_count: 0,
                    views: 3,
                    sessions: 0,
                    highlights: [
                        {
                            _id: '667ecd10cdf5d220bd4a7e4e',
                            rank: {
                                score: 0,
                                basis: [],
                                successIndicator: 0,
                            },
                            preview_cache: {
                                images: [],
                            },
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                            contentSubType: 'post',
                            visibility: 'public',
                            source: 'Import',
                            contentSource: 'Manual',
                            genSource: 'Manual',
                            hasGenVariant: false,
                            genType: 'Manual',
                            approvalStatus: 'U',
                            edited: false,
                            status: 'P',
                            importStatus: 'pending',
                            influencerStatus: 'complete',
                            attempts: 1,
                            err: 'false',
                            publish_err: '',
                            validation_err: '',
                            tags: [],
                            content: 'Utilizing generative AI is crucial for optimizing digital transformation efforts, enhancing efficiency, and mitigating expenses. Failure to incorporate this technology may result in missed opportunities for accelerated transformation and cost reduction.  @[KBSJ Partners](urn:li:organization:11050203) https://lnkd.in/d7rr3Ymx',
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            createdAt: '2024-06-27T22:37:13.878Z',
                            updatedAt: '2024-06-28T14:47:44.792Z',
                            publishAt: '2024-06-27T22:37:13.878Z',
                            contentType: 'linkedin',
                            attachments: [],
                            channel: '11050203',
                            createdBy: '572ca58d8178509b0e04a62a',
                            url: 'https://www.linkedin.com/feed/update/urn:li:share:7212222471226236929',
                            post_meta: {
                                uniqueImpressionsCount: 3,
                                shareCount: 0,
                                engagement: 0,
                                clickCount: 0,
                                likeCount: 0,
                                impressionCount: 3,
                                commentCount: 0,
                                like_count: 0,
                                share_count: 0,
                            },
                            channel_meta: {
                                id: '661acbc4885b9517ed6f29b0',
                                name: 'KBSJ Partners',
                                logo: '',
                                handle: 'kbsj-partners',
                            },
                            author_meta: {
                                id: '11050203',
                                name: 'KBSJ Partners',
                                description: 'We have committed to seamlessly optimize prospective sources so that we may endeavor to continually synergize cost effective technology.',
                                url: 'https://linkedin.com/company/kbsj-partners',
                                logo: '',
                                handle: 'kbsj-partners',
                                numConnections: 7,
                            },
                            publish_errors: [],
                            originalContent: 'Utilizing generative AI is crucial for optimizing digital transformation efforts, enhancing efficiency, and mitigating expenses. Failure to incorporate this technology may result in missed opportunities for accelerated transformation and cost reduction.  @[KBSJ Partners](urn:li:organization:11050203) https://lnkd.in/d7rr3Ymx',
                            __v: 0,
                            impressions: null,
                            analytic_content: {
                                frags: ['Utilizing', 'generative', 'AI', 'is', 'crucial', 'for', 'optimizing', 'digital', 'transformation', 'efforts,', 'enhancing', 'efficiency,', 'and', 'mitigating', 'expenses.', 'Failure', 'to', 'incorporate', 'this', 'technology', 'may', 'result', 'in', 'missed', 'opportunities', 'for', 'accelerated', 'transformation', 'and', 'cost', 'reduction.', '', 'Partners](urn:li:organization:11050203)'],
                            },
                            urls: [
                                {
                                    type: 'url',
                                    value: 'lnkd.in/d7rr3Ymx',
                                    isLink: true,
                                    href: 'https://lnkd.in/d7rr3Ymx',
                                    start: 302,
                                    end: 326,
                                },
                            ],
                            preview: {
                                images: ['https://static.licdn.com/scds/common/u/images/logos/favicons/v1/favicon.ico'],
                                videos: [],
                                audios: [],
                                contentType: 'text/html',
                                title: 'LinkedIn',
                                description: 'This link will take you to a page that’s not on LinkedIn',
                                mediaType: 'article',
                                meta: {
                                    image: 'https://static.licdn.com/scds/common/u/images/logos/favicons/v1/favicon.ico',
                                    title: 'LinkedIn',
                                    description: 'This link will take you to a page that’s not on LinkedIn',
                                    valid: true,
                                },
                            },
                            analytics_meta: false,
                            $$hashKey: 'object:1715',
                        },
                        {
                            _id: '667ecd10cdf5d220bd4a7e4f',
                            rank: {
                                score: 0,
                                basis: [],
                                successIndicator: 0,
                            },
                            preview_cache: {
                                images: [],
                            },
                            campaign: ['59caeeb2a0da3e1ecf49bac7'],
                            contentSubType: 'post',
                            visibility: 'public',
                            source: 'Import',
                            contentSource: 'Manual',
                            genSource: 'Manual',
                            hasGenVariant: false,
                            genType: 'Manual',
                            approvalStatus: 'U',
                            edited: false,
                            status: 'P',
                            importStatus: 'pending',
                            influencerStatus: 'complete',
                            attempts: 1,
                            err: 'false',
                            publish_err: '',
                            validation_err: '',
                            tags: [],
                            content: "Living a healthy lifestyle can increase your chances of living longer! 🌿💪 According to a recent study, those with the highest healthy-lifestyle score were 60% more likely to become centenarians. However, it's important to remember that this is just one piece of the puzzle when it comes to longevity. Let's continue to explore  @[KBSJ Partners](urn:li:organization:11050203)  different aspects of healthy living to uncover all the nuances. #HealthyLiving #Longevity #CentenarianStudy 🌟 https://lnkd.in/gmzBbzXG",
                            dashboard: '59b8bc3e618ffc164908bbfb',
                            createdAt: '2024-06-27T22:20:19.209Z',
                            updatedAt: '2024-06-28T14:47:44.793Z',
                            publishAt: '2024-06-27T22:20:19.209Z',
                            contentType: 'linkedin',
                            attachments: [],
                            channel: '11050203',
                            createdBy: '572ca58d8178509b0e04a62a',
                            url: 'https://www.linkedin.com/feed/update/urn:li:share:7212218215341469696',
                            channel_meta: {
                                id: '661acbc4885b9517ed6f29b0',
                                name: 'KBSJ Partners',
                                logo: '',
                                handle: 'kbsj-partners',
                            },
                            author_meta: {
                                id: '11050203',
                                name: 'KBSJ Partners',
                                description: 'We have committed to seamlessly optimize prospective sources so that we may endeavor to continually synergize cost effective technology.',
                                url: 'https://linkedin.com/company/kbsj-partners',
                                logo: '',
                                handle: 'kbsj-partners',
                                numConnections: 7,
                            },
                            publish_errors: [],
                            originalContent: "Living a healthy lifestyle can increase your chances of living longer! 🌿💪 According to a recent study, those with the highest healthy-lifestyle score were 60% more likely to become centenarians. However, it's important to remember that this is just one piece of the puzzle when it comes to longevity. Let's continue to explore  @[KBSJ Partners](urn:li:organization:11050203)  different aspects of healthy living to uncover all the nuances. #HealthyLiving #Longevity #CentenarianStudy 🌟 https://lnkd.in/gmzBbzXG",
                            __v: 0,
                            post_meta: {
                                like_count: 0,
                                likeCount: 0,
                                share_count: 0,
                                shareCount: 0,
                            },
                            impressions: null,
                            urls: [
                                {
                                    type: 'url',
                                    value: 'lnkd.in/gmzBbzXG',
                                    isLink: true,
                                    href: 'https://lnkd.in/gmzBbzXG',
                                    start: 489,
                                    end: 513,
                                },
                            ],
                            preview: {
                                images: ['https://static.licdn.com/scds/common/u/images/logos/favicons/v1/favicon.ico'],
                                videos: [],
                                audios: [],
                                contentType: 'text/html',
                                title: 'LinkedIn',
                                description: 'This link will take you to a page that’s not on LinkedIn',
                                mediaType: 'article',
                                meta: {
                                    image: 'https://static.licdn.com/scds/common/u/images/logos/favicons/v1/favicon.ico',
                                    title: 'LinkedIn',
                                    description: 'This link will take you to a page that’s not on LinkedIn',
                                    valid: true,
                                },
                            },
                            analytics_meta: false,
                            $$hashKey: 'object:1716',
                        },
                    ],
                    id: '11050203',
                    name: 'KBSJ Partners',
                    followers: 0,
                    $$hashKey: 'object:1680',
                },
                {
                    type: 'youtube',
                    total_posts: 0,
                    impressions: 0,
                    like_count: 0,
                    comment_count: 0,
                    favorite_count: 0,
                    views: 0,
                    sessions: 0,
                    highlights: [],
                    id: 'UCZXcrR4_kZmzicHK7H5z0Vg',
                    color: '#FF0000',
                    name: 'Greg Hamel',
                    $$hashKey: 'object:1681',
                },
            ],
            aggregate: {
                total_posts: 4,
                favorite_count: 0,
                retweet_count: 0,
                like_count: 0,
                impressions: 12,
                sessions: 0,
                reach: 14,
                share_count: 0,
                comment_count: 0,
                followers: 0,
            },
            authors: {
                '572ca58d8178509b0e04a62a': {
                    id: '572ca58d8178509b0e04a62a',
                    sessions: 0,
                    impressions: 9,
                    total: 9,
                    email: 'greg@itactual.com',
                    avatar: 'https://cdn.filestackcontent.com/3wEv2hv7SJqDfavthGEc',
                    fullname: 'Gregory Hamel',
                    username: 'greghamel',
                    gravatar_valid: false,
                },
            },
            cumulative: [
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '09,0,9,18,23,0,3,[object Object],20,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
                '00,0,0,,10,0,0,[object Object],10,0,0,0,1',
            ],
            promoters: {
                byImpressions: [],
                byEngagements: [],
            },
        },
    ],
    arrayOfHighlights: [],
}
