<template>

  <div class="d-flex flex-row select-filter-actions">

    <!-- select toggle -->
    <div class="select">
      <span class="select">
        <i class="fa fa-check" @click="toggleCheck" :class="{all:content.length && selections.length===content.length,some:selections.length&&selections.length!==content.length,none:!selections.length}" />
      </span>
    </div>

    <!-- filters -->
    <div class="filter">
      <MediaTypeFilter class="item" v-if="config.filter.mediaType && config.filter.mediaType.editable" :dashboard="dashboard" :config="config.filter.mediaType" @on-filter="onFilter" />
      <ChannelFilter class="item" v-if="config.filter.channels && config.filter.channels.editable" :dashboard="dashboard" :config="config.filter.channels" @on-filter="onFilter" />
      <StatusFilter class="item" v-if="config.filter.status && config.filter.status.editable" :dashboard="dashboard" :config="config.filter.status" @on-filter="onFilter" />
    </div>

    <!-- actions -->
    <div class="actions flex-grow-1" v-if="content.length">
      <div class="left">
        <b-button class="action" v-for="(action,idx) in leftActions" :key="action.id"
          :variant="action.variant" @click="onAction(action.id)"
          :class="action.cls" :disabled="!content.length">
          <span v-if="!selections.length"> {{action.title.prefix|upperCase}} ALL {{action.title.suffix|upperCase}}</span>
          <span v-if="selections.length"> {{action.title.prefix|upperCase}} {{selections.length}} {{action.title.suffix|upperCase}}</span>
        </b-button>
      </div>
      <div class="right float-right">
        <b-button class="action" v-for="(action,idx) in rightActions" :key="action.id"
          :variant="action.variant" @click="onAction(action.id)"
          :class="action.cls" :disabled="!content.length">
          <span v-if="!selections.length"> {{action.title.prefix|upperCase}} ALL {{action.title.suffix|upperCase}}</span>
          <span v-if="selections.length"> {{action.title.prefix|upperCase}} {{selections.length}} {{action.title.suffix|upperCase}}</span>
        </b-button>
      </div>
    </div>

  </div>

</template>

<script>

import { getters } from '@/services/store'

import MediaTypeFilter from './MediaTypeFilter'
import ChannelFilter from './ChannelFilter'
import StatusFilter from './StatusFilter'

export default {
  name: 'FilterActions',
  data() {
    return {
      partner: getters.partner()
    }
  },
  props: {
    dashboard: {
      type:Object,
      required:true
    },
    content: {
      type: Array,
    },
    selections: {
      type: Array,
    },
    channels: {
      type: Array
    },
    config: {
      type: Object,
      required:true
    }
  },
  created() {
    this.partner = getters.partner()
  },
  methods: {

    onFilter(ev) {
      this.$emit('on-filter',ev)
    },

    onAction(ev) {
      this.$emit('on-action',{action:ev})
    },

    // selection handler
    toggleCheck() {

      // select all
      if ( !this.selections.length || this.selections.length === this.content.length ) {
        this.content.forEach((c)=>{
          this.$emit('on-select',c._id)
        })
      }

      // select remaining
      else {
        this.content.forEach((c)=>{
          if ( !this.selections.includes(c._id) ) {
            this.$emit('on-select',c._id)
          }
        })
      }
    }

  },

  computed: {
    leftActions() {
      return this.config.actions.filter((a)=>{
        const result = a.float==='left' && (a.partner === '*' || (a.partner === this.partner))
        return result
      })
    },
    rightActions() {
      return this.config.actions.filter((a)=>{
        const result = a.float==='right' && (a.partner === '*' || (a.partner === this.partner))
        return result
      })
    }
  },

  components: {
    MediaTypeFilter,
    ChannelFilter,
    StatusFilter
  }

}
</script>

<style lang="scss" >

.select-filter-actions {

  font-family: Noto Sans;

  margin-top:40px;
  margin-bottom:40px;

  .select {

    margin: 5px 8px 0px 9px;
    display: inline;

    i {
      font-size: 24px;
      padding: 2px;
      border: 1px solid #C6C6C6;
      border-radius: 6px;
    }

    .none {
      color:white;
    }

    .some {
      color: black;
      border-color: black
    }

    .all {
      color: white;
      background-color:#14423A;
      border-color: #14423A
    }

  }

  .filter {
    display: inline;
    .item {
      margin-right: 20px;
      display:inline;
    }
  }

  .actions {
    display: inline;
    .action {
      min-width: 200px;
      margin-left: 20px;
      font-weight: bold;
      border-radius: 10px;
    }
    .action.primary {
      color: white;
      border-color: #008CFF!important;
      background-color: #008CFF!important;
    }
    .left {
      display:inline;
      text-align:left;
    }
    .right {
      display:inline;
      text-align:right;

    }
  }


}

</style>
