<template>
  <b-modal class="confirm-modal" :id="id" centered :title="title" hide-footer>
    <p v-if="description">{{description}}</p>
    <span class="float-right">
      <b-button v-if="!nocancel" class="confirm-modal-button" @click="click()" variant="outline-secondary">Cancel</b-button>
      <b-button class="confirm-modal-button" @click="click(true)" variant="primary">Ok</b-button>
    </span>
  </b-modal>
</template>

<script>

'use strict'

export default {

  name: 'ConfirmDialog',

  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    click: {
      type: Function,
    },
    open: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'modal-confirm-dialog'
    },
    nocancel: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    open() {
      if ( this.open ) {
        this.$bvModal.show(this.id);
      } else this.$bvModal.hide(this.id);
    }
  }

}

</script>

<style>

.modal {
  z-index:1200!important;
}

.modal-header .close {
  display:none;
}

.confirm-modal-button {
  margin-left:10px;
}


</style>
