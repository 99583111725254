<template>
  <div class="attachment">
    <div class="remove-attachment" v-b-tooltip.hover title="Remove attachment" @click="removeAttachment(attachment)">
      <span class="fa-stack fa-lg">
        <i class="fa fa-solid fa-circle fa-stack-2x"></i>
        <i class="fa fa-solid fa-inverse fa-stack-1x fa-times" style="color:white;opacity:1.0;"></i>
      </span>
    </div>
    <div v-if="attachment.mimetype.startsWith('image/')" class="edit-attachment" v-b-tooltip.hover title="Edit attachment" @click="editAttachment(attachment)">
      <span class="fa-stack fa-lg">
        <i class="fa fa-solid fa-circle fa-stack-2x"></i>
        <i class="fa fa-solid fa-inverse fa-stack-1x fa-pencil" style="color:white;opacity:1.0;"></i>
      </span>
    </div>    
    <div v-if="attachment.mimetype.startsWith('image/')" class="text-center">
      <b-img fluid :src="attachment|thumbnail(policy)" />
    </div>
    <div v-if="attachment.mimetype.startsWith('video/')">
      <video width="100%" style="min-height:80px" controls>
        <source :src="attachment|preview(policy)" />
      </video>
    </div>
  </div>
</template>

<style lang="scss" >

.invalid-attachment {
  border: 1px solid red;
  border-radius: 5px;
}

.remove-attachment {
  transform: scale(0.5);
  position: absolute;
  right: -10px;
  top: -20px;
  opacity: 1.0;
}

.edit-attachment {
  transform: scale(0.5);
  position: absolute;
  right: 10px;
  top: -20px;
  opacity: 1.0;
}

.attachment-details {
  padding-left:5px;
}

</style>


<script>

import { media } from '@/services/constants'
import { getters } from '@/services/store'
import moment from 'moment'

export default {

  name: 'Attachment',

  data() {
    return {
      width: 0,
      height:0,
      aspect: 1,
      valid: true
    }
  },
  props: {
    post: {
      type: Object,
      required: true
    },
    attachment: {
      type: Object,
      required: true,
    },
    policy: {
      type: Object,
      required: true
    },
    errors: {
      type: Array,
      required: true
    }
  },

  watch: {

    'post.attachments': {
      handler() {
        this.validate()
      },
      deep: true
    }

  },

  methods: {

    push( message, cls ) {
      const aidx = this.post.attachments.indexOf(this.attachment)
      if ( cls === 'danger' ) {
        const entry = {
          idx: aidx,
          type: 'attachment',
          message: message
        }

        this.$emit('attach-error', entry)

      }
    },

    // handled by ContentPost
    removeAttachment() {
      this.$emit('detached',this.attachment)
    },

    // send to Vista editor
    editAttachment(a) {
      this.$emit('editing',this.attachment)      
    },    

    /**
     * ported directly from enterprise
     **/

    validate() {

      const aidx = this.post.attachments.indexOf(this.attachment)
      if ( aidx == -1 ) return; // no longer attached

      const post = this.post
      const type = post.contentType === 'temporary'? post.channel : post.contentType
      const props = media[type]
      const advice = {}

      function durationToMs(duration) {
        var t = moment(duration,'HH:mm:ss')
        return ((t.hour() * 3600) +
              (t.minute() * 60 ) +
              (t.second())) * 1000
      }

      var images = this.post.attachments.filter(function(a) {return a.mimetype && a.mimetype.startsWith('image/') });
      var audios = this.post.attachments.filter(function(a) {return a.mimetype && a.mimetype.startsWith('audio/') });
      var videos = this.post.attachments.filter(function(a) {return a.mimetype && a.mimetype.startsWith('video/') });

      // console.log('attachment.validate', aidx, images.length, audios.length, videos.length )

      videos = videos.reduce(function(acc,v) {
        if ( v.clip ) {
          acc.push({
            duration:durationToMs(v.clip.duration),
            size:10000 // guestimate
          })
        } else acc.push(v)
        return acc
      },[])

      var minImages = (videos.length)? 0 : props.minImages
      var maxImages = (videos.length)? 0 : props.maxImages
      var maxVideos = (images.length)? 0 : props.maxVideos

      // disable audio for all
      if ( audios.length )  {
        this.push(type+' does not support audio attachments','danger')
      }

      if ( type == 'twitter' ) {

        var maxImageSize = 5000000

        if ( videos.length ) {

          const user = getters.user() || {flags:[]}

          if ( videos[0].duration > 140000 && !user.flags.includes('feature_twitter_long_videos') ) {
            this.push('Twitter Videos must be less than 2:20 in duration','danger')
          }
          if ( videos[0].size > 500 * 1000 * 1024 && !user.flags.includes('feature_twitter_long_videos') ) {
            this.push('Twitter Videos must be less than 512 Mb in size','danger')
          }

        } else if ( images.length ) {
          this.post.attachments.forEach(function(a) {
            if ( a.size > maxImageSize ) {
              this.push( a.filename + ' is too large. The maximum allowable size for Twitter images is 5MB','danger' )
            }
          })
          advice.disablePickVideo = true

        }
      }

      else if ( type == 'facebook' ) {
        if ( videos.length ) {
          if ( videos[0].duration > 20 * 60 * 1000 ) { // 20m
            this.push('Facebook Videos must be less than 20m in duration','danger')
          }
          if ( videos[0].size > 1000000000 ) { // 1Gb
            this.push('Facebook Videos must be less than 15Mb in size','danger')
          }
        }
      }

      else if ( type == 'instagram' ) {

        if ( videos.length ) {
          if ( videos[0].duration > 60 * 1000 * 10 ) { // 10m
            this.push('Instagram Videos must be less than 10m in duration','danger')
          }
        } else if ( images.length ) {
            if ( post.attachments[0].size > 8 * 1024 * 1024 ) {
              this.push('Instagram requires images smaller than 8 Mb','danger')
            }
        }

        // TODO - MOVE TO CONTENTSTATUS
        if ( post.content ) {
          var tags = post.content.match(/(#[a-z0-9][a-z0-9\-_]*)/ig) || []
          if ( tags.length > 30 ) {
            this.push('Instagram permits a maximum of 30 hashtags','danger')
          }
        }
      }

      else if ( type == 'pinterest' ) {
        if ( videos.length ) {
          this.push('Pinterest does not support Video attachments','danger')
        }
      }

      else if ( type == 'linkedin' ) {
        if ( videos.length && post.status != 'DT' ) {
          if ( videos[0].size > 200 * 1024 * 1024 ) { // 200m
            this.push('Linkedin videos must currently be less than 200M in size','danger')
          } else {
            this.push('Linkedin video support is currently in Beta','warning')
          }
        }
      }

      else if ( type == 'youtube' ) {
        if ( !post.title || !post.title.length || !post.content || !post.content.length ) {
          this.push( 'Youtube requires a title and description','danger')
        }
        if ( videos.length != 1 ) {
          this.push( 'Youtube requires one video attachment','danger')
        } else {

          if ( videos[0].duration > (12 * 60 * 60 * 1000) ) { //12h
            this.push('Youtube Videos must be less than 12h in duration','danger')
          }
          if ( videos[0].size > 20000000000 ) {
            this.push('Youtube Videos must be less than 20Gb in size','danger')
          }

        }
      }

      // image aspect ratio tests
      if ( type === 'instagram' ) {

        // size must be limited to 8M
        if ( this.attachment.size > 8 * 1024 * 1024 ) {
          this.push('Instagram requires images smaller than 8 Mb','danger')
        }

        if ( this.aspect < 0.8 || this.aspect > 1.91 ) {
          this.push('Instagram requires images with an aspect ratio between 0.8 and 1.91 (width/height)','danger')
        }

      } else {

        if ( this.aspect > 4) {
            this.push("Image is very wide and short, we recommend a ratio of 2:1 width to height to make sure the image doesn't get cropped or stretched.",'warning')
        }

        if ( this.aspect < 0.51) {
            ratio = Math.round(100*ratio)/100;
            this.push("Image is very tall and thin, we recommend a ratio of 2:1 width to height to make sure the image doesn't get cropped or stretched.",'warning')
        }

        if ( this.width < 220){
          this.push("Image is very small, we recommend a minimum width of 220px to make sure it's large enough to see and doesn't get stretched",'warning')
        }

      }

      this.valid = this.errors.filter((e)=>{return e.idx === aidx}).length === 0

    }

  },
  created() {

    const type = (this.post.channel || this.post.contentType)
    const props = media[type]

    if ( this.attachment.mimetype && this.attachment.mimetype.startsWith('image') ) {

      let img = new Image();

      img.onload = () => {
        this.width = img.width
        this.height = img.height
        this.aspect = this.width/this.height
        this.validate()
      }

      img.src = this.$options.filters.preview(this.attachment,this.policy)

    } else {

      this.validate()

    }
  }
}
</script>

