<template>

  <div class="signup-wizard" v-if="ready">
    <AccountInfo v-if="!user || !user._id" @account-info-selected="setAccountInfo" :message="message" />
    <PublisherInfo v-else-if="!user.signup.publisher" @publisher-info-selected="setPublisherInfo" :message="message" />
    <ProductInfo v-else="!user.signup.publisher || !subscription || subscription.status !== 'active'" :message="message" :user="user" />
  </div>

</template>

<script>

'use strict'

import { actions, getters, authenticated } from '@/services/store';

import PublisherInfo from './PublisherInfo'
import ProductInfo from './ProductInfo'
import AccountInfo from './AccountInfo'

export default {

  name: 'SignupWizard',

  /**
   * this needs rework to move data definition into subcomponents
  **/

  data() {
    return {
      user: undefined,
      plan: undefined,
      subscription: undefined,
      dashboard: undefined,
      ready: false,
      message: ''
    }
  },

  async created() {

    document.body.style.backgroundColor = '#F7F7F7';

    if ( authenticated() ) {
      this.user = getters.user()
      this.subscription = getters.subscription() 
    } 
    this.ready = true;

  },

  methods: {

    async setPaymentInfo(account) {

    },

    // creates the user
    async setAccountInfo(account) {

      this.message = ''

      account.firstName = account.email.split('@')[0]
      account.lastName = ''

      const values = {
        referrer: document.referrer,
        signup: {
          state: 'signup',
          account: account,
        }
      }

      try {
        let query = this.$router.currentRoute.query
        let resp = await actions.createTrialUser(values,query)
        this.subscription = resp.subscription
        this.dashboard = resp.dashboard        
        this.user = resp.user
      } catch( err ) {
        console.error(err)
        grecaptcha.reset();
        this.message = err.message
      }

    },

    async setPublisherInfo(publisher) {
      await actions.patchUser( this.user._id, 'signup.publisher', publisher )
      this.user = getters.user()
      this.$router.push('/${this.dashboard._id}/onboarding')
    },

  },

  components: {
    PublisherInfo,
    AccountInfo,
    ProductInfo
  }

}
</script>

<style lang="scss" >

.signup-wizard {

  min-height: 100vh;
  margin-top: -30px;
  background-color: #F7F7F7;
  font-family: Noto Sans;

  .signup-page {
    height: 100%!important;
    background-color: #F7F7F7;

    .title {
      font-size: 42px;
      font-weight: bold;
    }

    .description {
      font-size: 26px;
    }

  }

  // disable form validation feedback
  .was-validated .form-control:invalid, .form-control.is-invalid {
      border-color: #e0b6ba!important;
      background-image:none !important;
  }

  .was-validated .form-control:valid, .form-control.is-valid {
      border-color: #b1dac8!important;
  }

  .invalid-feedback {
    visibility:hidden!important;
  }

}


</style>
