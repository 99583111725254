<template>

  <div class="container progress-container-v3-container nopad" v-if="session">

    <!-- random content -->
    <div class="row" v-if="session.status.bucket !== 'failed' && session.status.bucket !== 'transcribed'">
      <div class="col-md-9 offset-2 nopad">
        <h3 class="title">What's Lately's AI Doing Now?</h3>
      </div>
    </div>

    <div class="row" v-if="showBeforeResults">

      <div class="col-md-9 offset-2 nopad">

        <div class="before-results">
          <div class="row">
            <div class="col text-center">
              <h3>Before we show you the results... </h3>
              <p>FYI, Lately's AI learns from <i>everything</i> you do:</p>

              <div class="bullets-container">
                <div class="bullets text-left">
                  <p>&#8226; Every piece of longform content you ingest.</p>
                  <p>&#8226; Every edit you make.</p>
                  <p>&#8226; Every post you publish.</p>
                  <p>&#8226; Every post you trash.</p>     
                </div>                           
              </div>

              <p class="summary">The more you do, the faster it learns, and the <br/> more gob-smaking your results will be!</p>
              <b-button variant="primary" @click="showResults()">GOT IT!</b-button>

            </div>
          </div>
        </div>     
      </div>
    </div> 
        
    <div class="row" v-else>
      <div class="col-md-9 offset-2 nopad">        

        <div class="progress-container-v3 nopad">

          <!-- generate failed -->
          <div class="container noresults" v-if="session.status.bucket === 'failed'" >
            <NoResultsView :session="session" />
          </div>

          <!-- video transcribed - show editor -->
          <div class="text-left transcript" v-else-if="session.status.bucket==='transcribed'" >
            <div class="row">
              <div class="col">
                <TranscriptReview :session="session" @resume-session="resumeSession" />
              </div>
            </div>
          </div>

          <!-- otherwise show progress -->
          <div class="row text-left nopad" v-else>

            <div class="d-flex nopad">

              <div class="progress-animation">

                <b-img fluid :src="require('@/assets/loading_green.gif')" />

                <div v-if="session.status.bucket !== 'failed' && session.status.bucket !== 'transcribed'">
                  <span class="random-message">
                    <b v-if="!complete">{{message}}</b>
                    <b v-else>We're all set!</b>
                  </span>
                </div>

              </div>

              <div class="text-left progress-steps">

                <!-- progress steps -->
                <div class="d-flex progress-step" v-for="(step,idx) in steps" :key="idx" v-bind:class="current===idx? 'step-complete':'step-incomplete'">
                  <span v-if="current >= idx" class="step-idx">{{idx+1}}. </span>
                  <span v-if="current >= idx" class="step-text">{{step.txt}} </span>
                </div>

                <div v-if="complete" class="progress-complete text-center">
                  <b-button @click="onShowBeforeResults()">SHOW ME MY RESULTS</b-button>
                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

import { progressMessages } from '@/services/constants'
import { actions, getters } from '@/services/store'

import TranscriptReview from './TranscriptReview'
import NoResultsView from './NoResultsView'

import Vue from 'vue'

// MINIMUM TIME TO SHOW PROGRESS (ms)
const MIN_PROGRESS = 12000

export default {

  name: 'ProgressIndicator',

  data() {
    return {
      polls: 0,
      steps: [],
      start: Date.now(),
      timer:false,
      complete:false,
      completed:[],
      random: [],
      messages: [],
      current: 0,
      firstTimeThru: false,
      showBeforeResults: false,
      message: 'Atomizing your blog post'
    }
  },

  props: {
    dashboard: {
      type: Object,
      required: true
    },
    session: {
      type: Object,
      required: true
    },
    sessionCount: {
      type: Number
    }
  },

  async created() {

    document.body.style.backgroundColor = '#00CC79';

    // fetch random messages
    try {
      this.messages = await actions.fetchRandomContent()
      this.randomize()
    } catch( err ) {
      console.error(`Unable to fetch random content : ${err}`)
    }

    // crude deep clone
    this.steps = progressMessages('url')

    this.firstTimeThru = this.sessionCount===1

    // poll
    this.setTimer()

  },

  destroyed() {
    clearTimeout(this.timer)
  },

  methods: {

    onShowBeforeResults() {
      document.body.style.backgroundColor = 'white';      
      if ( this.firstTimeThru ) {
        this.showBeforeResults = true
      } else this.showResults()
    },

    // does nothing until minimum time elapsesfs
    showResults() {
      document.body.style.backgroundColor = 'white';
      this.$emit('on-progress-complete')
    },

    setTimer() {
      this.timer = setTimeout(this.update,2000)
    },

    checked(step) {
      return this.completed.includes(step)
    },

    resumeSession() {
      this.$emit('resume-session')
    },

    // not particularly random but good enough for this purpose
    randomize() {
      this.random = this.messages.slice().sort(() => Math.random() - 0.5)
    },

    update() {

      this.polls += 1;

      const activity = this.session.status.activity

      if ( !this.random.length ) {
        this.randomize()
      }

      if ( this.random.length ) {
        this.message = this.random.pop() + '...'
      }

      // propagate progress into checklist
      activity.forEach((key)=>{
        let steps = this.steps.filter((s)=>{ return s.id.startsWith(key) })
        steps.forEach((s)=>{
          s.checked = true
        })
      })

      // track video progress
      if ( this.session.status.bucket === 'transcribing' ) {
        const vd = this.steps.slice().reverse().find((s)=>{return s.genre === 'VIDEO'})
        const vdIdx = vd? this.steps.indexOf(vd) : chIdx
        if ( vd ) {
          vd.checked = true
        }
      }

      // interpolate progress beyond the VIDEO step
      else if ( this.session.status.bucket !== 'transcribed' && this.session.status.bucket !== 'waiting' ) {

        // find the last checked item
        const ch = this.steps.slice().reverse().find((s)=>{return s.checked})
        const chIdx = this.steps.indexOf(ch)

        // find the last VIDEO step
        const vd = this.steps.slice().reverse().find((s)=>{return s.genre === 'VIDEO'})
        const vdIdx = vd? this.steps.indexOf(vd) : chIdx

        if (chIdx >= vdIdx ) {
          this.steps.filter((s)=>{return s.genre === 'VIDEO'}).forEach((s)=>{
            s.checked = true
          })
        }

        const final = this.session.status.bucket==='complete' ||
                     (this.session.status.bucket === 'generating' && this.session.postsCreated.all)?
                      this.steps.length-1 : this.steps.length - 1

        if ( chIdx === final ) {
          this.complete = true
          this.steps.forEach((s)=>{
            s.checked = true
          })
          this.current = this.steps.length-1
        }

        // if we have passed video processing interpolate
        else if ( !(this.poll % 2) && chIdx >= vdIdx ) {
          Vue.nextTick(()=>{
            this.steps[chIdx+1].checked = true
            this.current = chIdx+1
            if ( chIdx === (this.steps.length-1) && (Date.now() - this.start) > MIN_PROGRESS ) {
              setTimeout(()=>{
                this.complete = true
                this.showResults()
              },2000)
            }
          })
        }

      }

      this.setTimer()

    }

  },

  components: {
    TranscriptReview,
    NoResultsView
  }

}
</script>

<style lang="scss" >

.progress-container-v3-container {

  font-family: Noto Sans;
  margin-top: 60px;

  .title {
    color: white;
    font-size: 42px;
    font-weight: bold;
  }

}

.before-results {

  h3 {
    font-weight: bold;
  }

  .bullets-container {

    margin: 20px 0px 20px 0px;

    padding: 10px 220px 10px 220px;

    .bullets {
      border-radius: 20px;
      padding: 20px;
      font-size: 16px;
      background: #E8F9EF;
      box-shadow: 2px 2px 2px lightgrey;      
      p {
        margin-bottom: 10px;
      }
    }

  }

  button {
    margin-top: 20px;
    width: 200px;
    color: white;
    border-radius: 20px;
    padding: 10px;
    border: 1px solid #20C763;
    background-color: #20C763!important;
  }

}

.progress-container-v3 {

  border-radius: 20px;
  background-color: #E8F9EF;

  .progress-steps {
    border-radius: 0px 20px 20px 0px;
    background-color: white;
    padding: 40px!important;
    margin: 0px;
    width: 60%;
    img {
      width: 175px;
      margin: 0px 58px 0px 60px;
    }
  }

  .progress-complete {
    button {
      background-color: green;
      font-weight: bold;
      border-radius:3px
    }
  }

  .progress-animation {
    padding: 40px;
    width: 40%;
    text-align: center;
  }

  .progress-step {
    padding: 0px 0px 15px 0px;
    margin-bottom: 8px;
    i.step-check {
      padding-top: 12px;
      width: 28px;
    }
  }

  .step-idx {
    font-size: 22px;
    font-weight: bold;
    padding-right: 20px;
  }

  .step-text {
    color: #2D2D2D;
    font-size: 22px;
    i {
      margin-right: 10px!important;
    }
  }

  .step-incomplete {
    span {
      color: #C6C6C6;
      i {
        color: #C6C6C6;
      }
    }
  }

  .step-complete i {
    span {
      color: #20C763;
    }
  }

  .random-message {
    color: #56918E;
    font-size:18px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  .ready-message {
    color: black;
    padding: 28px 0px 36px 6px;
    font-size: 24px;
  }

  .nav-button {
    padding:15px 25px 15px 25px;
    font-size: 18px;
    font-weight: bold;
    background-color: #2D2D2D;
    color: white;
    border: 1px solid #2D2D2D;
    border-radius: 5px;
  }

  .nav-button.disabled {
    background-color: #E6E6E6;
    border: 0px;
    color: #C6C6C6;
  }

}

</style>
