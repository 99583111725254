<template>
  <div class="row">
    <div class="col text-center">
      <div class="well">
      <h1 class="msg">We're generating your posts!</h1>
      <p>If you'd like to carry on and generate more content feel free to <a :href="'#/'+dashboard._id+'/start'">Start another Session</a></p>

      <img src="../../assets/flask.gif" />
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GeneratorProgress',
  created() {
    setTimeout(()=>{
      this.nav(1)
    },3000)
  },
  props: {
    session:{
      type:Object,
      required:true
    },
    nav: {
      type: Function,
      required:true
    }
  }
}
</script>

<style lang="scss" >
h1.msg {
  color: $gray-900;
}
</style>
