<template>

  <div class="container-fluid results-header" v-if="session && dashboard" :class="{success:statusMessage && statusMessage.status==='success',error:statusMessage && statusMessage.status==='error'}">

    <!-- persistent -->
      <div class="row">
        <div class="col-md-5 offset-3 nopad">
          <div class="header" v-if="!statusMessage">
            <h3>Generator Results</h3>
            <span class="ai-toggle">
              <b-form-checkbox 
                id="genvariant_switch" 
                :disabled="session.isGenerative" 
                v-model="showOriginals" 
                @change="toggleAIOriginals()" switch class="">
                Show {{originalsRemaining[selectedChannel]}} AI Originals
                <small class="text-success" v-if="session.isGenerative"></small>
              </b-form-checkbox>
            </span>
          </div>
          <div v-else>
            <h3>
              <h3 class="title">{{statusMessage.title}}</h3>
              <h3 class="message">{{statusMessage.message}}</h3>
            </h3>
          </div>
        </div>

      </div>

  </div>

</template>

<style lang="scss" >

.results-header {

  position: relative;
  padding: 15px;
  height: 80px;
  top: -40px;    

  h3 {
    padding-left:5px;
    font-size: 24px;
    font-weight: 700;
  }  

  .header {
    padding-left: 5px;
    display: flex;
    justify-content: space-between;
  }

  .ai-toggle {
    font-size: 16px;
    font-weight: normal;
    margin-right: 15px;
    .custom-switch {
      margin-top: 5px;
    }
  }

  .title {
    font-size: 22px;
    font-weight: bold;
    margin-right: 20px;
  }

  .message {
    font-size: 18px;
    font-weight: regular;
    margin-right: 10px;
  }

}

.results-header.success {
  background-color: #00c976;
  color: white;
}

</style>

<script>

import SelectedSource from '@/components/SelectedSource'
import { actions } from '@/services/store'
import User from '@/services/user'

export default {

  name: 'ResultsHeader',

  data() {
    return {
      status:false,
      timer: false,
      message: 'AI Generator Results',
      messages: [],
      random: [],
      onboarded: false,
      showOriginals: true
    }
  },

  props: {
    dashboard: {
      type: Object,
      required: true
    },
    session: {
      type: Object,
      required: true
    },
    policy: {
      type: Object,
      required: true
    },
    content: {
      type: Array,
      default() {
        return []
      }
    },
    canShowV2: {
      type: Boolean
    },
    statusMessage: {
      type: Object
    },
    originalsRemaining: {
      type: Object
    },
    selectedChannel: {
      type:String
    }
  },

  methods: {

    toggleAIOriginals() {
      this.$emit('on-toggle-originals', this.showOriginals)
    },

    next() {

      const status = this.session.status.bucket

      // while we are in transcribed state show a fixed header
      if ( status === 'transcribed' ) {
        this.message = 'AI Generator needs your input :) '
        this.timer = setTimeout(this.next,4000)
      }

      // stop when we fail or have intermediate results
      else if ( status === 'failed' || (this.session.postsCreated && this.session.postsCreated.all ) ) {
        this.message = 'AI Generator Results'
      }

      // otherwise show random
      else {
        if ( this.random.length ) {
          this.message = this.random.pop() + '...'
          this.timer = setTimeout(this.next,4000)
        } else if ( this.messages.length ) {
          this.randomize()
          this.next()
        }
      }

    },

    // not particularly random but good enough for this purpose
    randomize() {
      this.random = this.messages.slice().sort(() => Math.random() - 0.5)
    }

  },

  // fetch random messages
  async created() {

    // this.showOriginals = this.session.isGenerative

    this.onboarded = User.getOnboardingKey('generator')

    this.canShowV2 = User.hasFlag('feature-results-v2')

    this.status = this.session.status.bucket;

    if ( this.status !== 'open' && this.status !== 'complete' && this.status !== 'failed' ) {

      try {
        this.messages = await actions.fetchRandomContent()
        this.randomize()
        this.next()
      } catch( err ) {
        console.error(`Unable to fetch random content : ${err}`)
      }

    }

  },

  destroyed() {
    clearTimeout(this.timer)
  },

  components: {
    SelectedSource
  }

}
</script>


