<template>

  <div class="container" >

    <AdminPanel />

  </div>

</template>

<script>

'use strict'

import AdminPanel from '../components/AdminPanel';

export default {

  name: 'Admin',

  components: {
  	AdminPanel
  }

}
</script>

<style>

</style>
