'use strict'

import * as filestack from 'filestack-js'
import { actions, getters } from './store'

export const picker = {
    /**
     * Obtain Vista api
     * @param {} options
     * @returns
     */
    async vistaInit(method, options = {}) {
        // todo - externalize this key
        let args = {
            apiKey: 'P9BDXT0-JBKMRS1-PH5DS10-8H51NHS',
            designType: 'facebookSM',
            downloadFormat: 'jpg',
            user: {
                externalUserId: 'platform.lately.ai',
            },
            translations: {
                en: [
                    {
                        key: 'headerButtonPublish',
                        value: 'Attach to Post',
                    },
                ],
            },
            sidebarTabs: ['designs', 'photos', 'video', 'music', 'text', 'styles', 'objects', 'background', 'brandkit'],
            sidebarTabsConfig: {
                designs: ['crello'],
                photos: ['partner', 'premium', 'stock'],
                video: true,
                music: ['explore', 'myMusic'],
                background: ['colors', 'photos', 'videos'],
                brandkit: true,
                objects: ['animated', 'static'],
                library: ['purchased', 'uploads'],
                styles: true,
                text: ['fonts', 'styles'],
            },
        }
        args = Object.assign(args, options)
        let api = await window.VistaCreateEditor.init(args)
        console.log('vistaInit', args)
        if (method === 'update') {
            await api.addImages({
                images: [options.url],
            })
        }
        return api
    },

    /**
     * Open createMedia editor to create image
     * @param {} post
     * @param type <string>
     * @param {*} options
     */
    async vistaCreate(post, type) {
        let options = {
            designType: `${type}SM`,
            onPublishAction: async (file) => {
                let dashboard = await getters.dashboard()
                let created = await actions.upload(dashboard._id, file)

                var intermediate = [created.data].map((f) => {
                    return {
                        file: f._id,
                        url: f.url,
                        duration: f.duration,
                        filename: f.filename,
                        mimetype: f.mimetype,
                        thumbnail: f.thumbnail,
                        title: f.title,
                        size: f.size,
                    }
                })

                post.attachments.push.apply(post.attachments, intermediate)

                // save the post
                await actions.updatePost({ _id: post.session, dashboard: dashboard._id }, post)
            },
        }
        await picker.vistaInit('create', options)
    },

    /**
     * Open createMedia editor to create image
     * @param {} post
     * @param type <string>
     * @param {*} options
     */
    async vistaEdit(post, attachment, type, policy) {
        return new Promise(async (resolve, reject) => {
            let url = attachment.url
            if (url.includes('filestackcontent')) {
                url = `${url}?signature=${policy.signature}&policy=${policy.policy}&cache=false`
            }
            let options = {
                designType: `${type}SM`,
                url: url,
                onPublishAction: async (file) => {
                    console.log('onPublishAction', { attachment, file })
                    let existing = post.attachments.find((a) => {
                        return a.url === attachment.url
                    })

                    let dashboard = getters.dashboard()

                    let updated = await actions.replace(dashboard._id, post, attachment, file)

                    console.log('edited', { updated, existing })
                    let idx = post.attachments.indexOf(existing)

                    // detach existing
                    post.attachments.splice(idx, 1)

                    // edit existing
                    existing.url = updated.url

                    // reattach existing
                    post.attachments.splice(idx, 0, existing)

                    resolve(post)
                },
            }
            await picker.vistaInit('update', options)
        })
    },

    pickFromCloud(session, options, policy) {
        return new Promise(async (resolve, reject) => {
            // default config goes to cdnlately-v3 bucket
            const defaults = {
                lang: 'en',
                uploadConfig: {
                    intelligent: true,
                },
                fromSources: options.fromSources,
                accept: options.accept,
                minFiles: options.minFiles || 1,
                maxFiles: options.maxFiles || 1,
                storeTo: {
                    container: 'cdnlately-v3', // updated
                    region: 'us-east-1',
                    access: 'public',
                    location: 's3',
                },
            }

            //var SERVICES = ['local_file_system','googledrive', 'box', 'dropbox', 'facebook', 'instagram', 'gmail']

            options.modalSize = [1024, 768]

            defaults.onUploadDone = async (results) => {
                for (let idx in results.filesUploaded) {
                    const f = results.filesUploaded[idx]

                    try {
                        const file = await actions.findOrCreateFile(session, f)
                        if (file) {
                            f._id = file._id
                        } else {
                            console.error('Unable to find or create file for uploaded', JSON.stringify(f, 0, 1))
                        }
                    } catch (err) {
                        // what to do here
                        console.error('findOrCreateFile caught', err)
                        this.$toasted.error(`Failed to upload file. Please try again, or select a different file`)
                    }
                }

                resolve(results)
            }

            const client = filestack.init(process.env.VUE_APP_FILESTACK_KEY, { security: policy })
            client.picker(defaults).open()
        })
    },
}
