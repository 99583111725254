<template>

  <!-- use a custom content class for styling -->
  <b-modal content-class="editor-modal" lazy id="editorModal" centered no-close-on-esc no-close-on-backdrop hide-header-close size="xl" v-if="post && session">
    <template #modal-header>
      <div class="container header-content border-0">
        <h2 class="modal-title">Edit Post</h2>
      </div>
    </template>

    <!-- Editor component -->
    <div class="editor-container">
      <Editor :show="{schedule:1}" :post="post" :policy="policy" :errors="errors" :session="session" :suppress="{actions:1}" :noSave="true" mode="inline" @update-schedule="handleUpdateSchedule" />
    </div>

    <template #modal-footer>
      <span class="action-buttons float-right">
        <b-button variant="default" @click="handleCancel"> Cancel </b-button>        
        <b-button variant="danger" @click="handleDelete"> Delete </b-button>        
        <b-button :disabled="(errors.length>0)" variant="primary" @click="onSubmit"> Save </b-button>
      </span>
    </template>
  </b-modal>
</template>

<style scoped lang="scss">

.editor-modal {

  .header-content {
    height: '100%';
    width: '100%';
    h2 {
      font-weight: bold;
      font-size: 40px;
    }
  }  

  .editor-container {
    padding-left:10px
  }

  .modal-header {
    padding: 0;
  }


  .action-buttons {
    button {
      margin-right: 5px;
    }
  }
}

</style>

<script>
import Editor from '@/components/ContentView/Editor'

export default {
  name: 'EditorModal',

  props: {
    post: {
      type: Object,
      required: false,
    },
    errors: {
      type: Array,
      required: true,
    },
    policy: {
      type: Object,
      required: true,
    },
    session: {
      type: Object,
      required: false
    },
  },

  components: {
    Editor
  },

  methods: {

    handleDelete() {
      this.$emit('post-deleted',this.post)     
    },

    handleUpdateSchedule(schedule) {
      this.$emit('update-schedule', schedule)
    },

    showModal() {
      this.$bvModal.show('editorModal')
    },

    handleCancel() {
      this.$emit('close-modal')
      this.$bvModal.hide('editorModal')
    },

    async onSubmit() {
      this.$emit('save-post');
      this.$bvModal.hide('editorModal')
    },
  },
}
</script>

